<template>
  <div class="content_body SaleBill" v-loading="loading">
    <div class="nav_header">
      <el-form :inline="true" :model="searchData" size="small" @keyup.enter.native="handleSearch">
        <el-form-item label="订单">
          <el-input v-model="searchData.SaleBillID" placeholder="请输入订单编号搜索" clearable @clear="handleSearch"></el-input>
        </el-form-item>
        <el-form-item label="客户">
          <el-input v-model="searchData.Name" placeholder="名字、手机号或客户编号" clearable @clear="handleSearch"></el-input>
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker @clear="handleSearch" unlink-panels v-model="searchData.createTime" type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSearch"></el-date-picker>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="searchData.BillType" placeholder="请选择订单类型" @clear="handleSearch" clearable @change="handleSearch">
            <el-option label="销售单" value="10"></el-option>
            <el-option label="销售退款单" value="20"></el-option>
            <el-option label="补欠款单" value="30"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchData.BillStatus" placeholder="请选择订单状态" @clear="handleSearch" clearable @change="handleSearch" style="width:140px;">
            <!-- <el-option label="待付款" value="10"></el-option> -->
            <el-option label="已完成" value="20"></el-option>
            <el-option label="已取消" value="30"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="返佣类型">
          <el-select v-model="searchData.ReturnedCommissionType" placeholder="请选择返佣类型" @clear="handleSearch" clearable @change="handleSearch" style="width:140px;">
            <el-option label="现金" value="10"></el-option>
            <el-option label="卡金" value="20"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="martp_10">
      <el-table size="small" ref="multipleTable" :data="saleOrderList" v-model="loading">
        <el-table-column prop="ID" label="订单编号"></el-table-column>
        <el-table-column prop="EntityName" label="门店名称"></el-table-column>
        <el-table-column prop="Name" label="客户信息" width="220px">
          <template slot-scope="scope">
            <div>{{scope.row.Name}} <span v-show="scope.row.Code">({{scope.row.Code}})</span> </div>
            <div v-if="scope.row.CustomerID != null">手机号：{{scope.row.PhoneNumber}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="Amount" label="订单金额">
          <template slot-scope="scope">￥{{scope.row.Amount | NumFormat}}</template>
        </el-table-column>
        <el-table-column prop="PayAmount" label="支付金额">
          <template slot-scope="scope">￥{{scope.row.PayAmount | NumFormat}}</template>
        </el-table-column>
        <el-table-column prop="CardDeductionAmount" label="卡抵扣金额">
          <template slot-scope="scope">
            <div v-if="scope.row.BillType==10||scope.row.BillType==20">￥{{scope.row.CardDeductionAmount | NumFormat}}</div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column prop="ArrearAmount" label="欠款金额">
          <template slot-scope="scope">
            <div v-if="scope.row.BillType==10">￥{{scope.row.ArrearAmount | NumFormat}}</div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column prop="ReturnedCommissionType" label="返佣类型">
          <template slot-scope="{row}">
            <span v-if="row.ReturnedCommissionType">{{row.ReturnedCommissionType}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="ReturnedCommissionScale" label="返佣比例">
          <template slot-scope="{row}">
            <span v-if="row.ReturnedCommissionScale">{{row.ReturnedCommissionScale+'%'}}</span>
            <span v-else>0%</span>
          </template>
        </el-table-column>
        <el-table-column prop="ReturnedCommissionAmount" label="返佣金额"></el-table-column>
        <el-table-column prop="ReturnedCommissionName" label="返佣人"></el-table-column>
        <el-table-column prop="ActualCollection" label="公司实际收款" width="120"></el-table-column>
        <el-table-column prop="BillType" label="订单类型">
          <template slot-scope="scope">{{scope.row.BillType == 10?'销售单':scope.row.BillType == 20?'销售退款单':'补欠款单'}}</template>
        </el-table-column>
        <el-table-column prop="BillStatus" label="订单状态">
          <template slot-scope="scope">{{scope.row.BillStatus == 10?'待付款':scope.row.BillStatus == 20?'已完成':'已取消'}}</template>
        </el-table-column>
        <el-table-column prop="EmployeeName" label="收银员"></el-table-column>
        <el-table-column prop="BillDate" label="下单时间"></el-table-column>
        <el-table-column prop="CreatedOn" label="录单时间"></el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button @click="getOrderDetail(scope.row)" v-prevent-click type="primary" size="small">详情</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pad_15 text_right">
        <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>
    <!-- 销售订单详情 -->
    <el-dialog :visible.sync="dialogVisible" width="1100px">
      <el-tabs v-model="saleActiveName">
        <el-tab-pane label="销售订单详情" name="0">
          <div class="font_13" style="height:60vh">
            <el-scrollbar class="el-scrollbar_height">
              <SaleBillDetailContent :saleOrderDetail="saleOrderDetail" @refreshBillList="RefreshList" :isRemark="true" @saveRemark="saveRemarkClick"></SaleBillDetailContent>
            </el-scrollbar>
          </div>
        </el-tab-pane>
        <el-tab-pane label="客户签字" name="1">
           <div style="height:60vh;" >
            <el-scrollbar class="el-scrollbar_height">
                <div v-if="Signature.CreatedOn" class="marbm_10">签字时间：{{Signature.CreatedOn}}</div>
                <el-card class="box-card" v-if="Signature.ImageURL">
                  <el-image style="width: 320px; height: 450px;"  :src="Signature.ImageURL" fit="cover"></el-image>
                </el-card>
            </el-scrollbar>
           </div>
        </el-tab-pane>
        <el-tab-pane label="图片上传" name="2">
          <div style="height:60vh;">
            <el-scrollbar class="el-scrollbar_height">
              <el-upload action="#" list-type="picture-card" :before-upload="updataimg" :file-list="fileList" :http-request="unset" :multiple="true" accept=".gif, .jpg, .jpeg, .png, .GIF, .JPG, .PNG">
                <i class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <el-image class="el-upload-list__item-thumbnail" :id="file.name" :src="file.url" :preview-src-list="showFileList" :z-index="99999999999" />
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="DialogPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>

                    <span v-if="isDelete" class="el-upload-list__item-delete" @click="deleteImg(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-scrollbar>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" :hidden="saleActiveName=='1'">
        <el-button @click="dialogVisible =false" size="small">取消</el-button>
        <el-button type="primary" @click="cashierReceiptDialogVisible = true" size="small">打印小票</el-button>
        <el-button type="danger" @click="CancelOrder" size="small" v-if="isCancel&saleOrderDetail.Cancel" v-prevent-click :loading="cancelSaleBillLoading">取消订单</el-button>
        <el-button v-if="isModifyPerformanceCommission&saleOrderDetail.BillStatus == '20'" @click="changePerformancecommission" size="small" type="primary" v-prevent-click :loading="modifyPerformanceCommissionLoading">修改经手人业绩提成</el-button>
      </span>
    </el-dialog>

    <!-- 销售补欠款订单详情 -->
    <el-dialog :visible.sync="arreardialogVisible" width="1100px">
      <el-tabs v-model="arrearActiveName">
        <el-tab-pane label="补欠款订单详情" name="0">
          <div class="font_13" style="height:60vh">
            <el-scrollbar class="el-scrollbar_height">
              <ArrearDetailContent :saleOrderDetail="saleOrderDetail"></ArrearDetailContent>
            </el-scrollbar>
          </div>
        </el-tab-pane>
        <el-tab-pane label="客户签字" name="1">
           <div style="height:60vh;" >
            <el-scrollbar class="el-scrollbar_height">
              <div v-if="Signature.CreatedOn" class="marbm_10">签字时间：{{Signature.CreatedOn}}</div>
              <el-card class="box-card" v-if="Signature.ImageURL">
                  <el-image style="width: 320px; height: 450px;"  :src="Signature.ImageURL" fit="cover"></el-image>
              </el-card>
            </el-scrollbar>
           </div>
        </el-tab-pane>
        <el-tab-pane label="图片上传" name="2">
          <div style="height:60vh">
            <el-scrollbar class="el-scrollbar_height">
              <el-upload action="#" list-type="picture-card" :before-upload="updataimg" :file-list="fileList" :http-request="unset" :multiple="true" accept=".gif, .jpg, .jpeg, .png, .GIF, .JPG, .PNG">
                <i class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <el-image class="el-upload-list__item-thumbnail" :id="file.name" :src="file.url" :preview-src-list="showFileList" :z-index="99999999999" />
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="DialogPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>

                    <span v-if="isDelete" class="el-upload-list__item-delete" @click="deleteImg(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-scrollbar>
          </div>
        </el-tab-pane>
      </el-tabs>

      <span slot="footer" :hidden="arrearActiveName=='1'">
        <el-button @click="arreardialogVisible =false" size="small">取消</el-button>
        <el-button type="primary" @click="arrearCashierReceiptDialogVisible = true" size="small">打印小票</el-button>
        <el-button type="danger" @click="CancelOrder" size="small" v-if="isCancel&saleOrderDetail.Cancel" v-prevent-click :loading="cancelSaleBillLoading">取消订单</el-button>
        <el-button v-if="isModifyPerformanceCommission&saleOrderDetail.BillStatus == '20'" @click="changePerformancecommission" size="small" type="primary" v-prevent-click :loading="modifyPerformanceCommissionLoading">修改经手人业绩提成</el-button>
      </span>
    </el-dialog>

    <!-- 销售退单详情 -->
    <el-dialog :visible.sync="refundDialogVisible" width="1100px">
      <el-tabs v-model="refundActiveName">
        <el-tab-pane label="销售退单详情" name="0">
          <div class="font_13" style="height:60vh">
            <el-scrollbar class="el-scrollbar_height">
              <RefundDetailContent :saleOrderDetail="saleOrderDetail"></RefundDetailContent>
            </el-scrollbar>
          </div>
        </el-tab-pane>
        <el-tab-pane label="客户签字" name="1">
           <div style="height:60vh;" >
            <el-scrollbar class="el-scrollbar_height">
              <div v-if="Signature.CreatedOn" class="marbm_10">签字时间：{{Signature.CreatedOn}}</div>
              <el-card class="box-card " v-if="Signature.ImageURL">
                  <el-image style="width:320px; height:450px;"  :src="Signature.ImageURL" fit="cover"></el-image>
              </el-card>
            </el-scrollbar>
           </div>
        </el-tab-pane>
        <el-tab-pane label="上传图片" name="2">
          <div style="height:60vh">
            <el-scrollbar class="el-scrollbar_height">
              <el-upload action="#" list-type="picture-card" :before-upload="updataimg" :file-list="fileList" :http-request="unset" :multiple="true" accept=".gif, .jpg, .jpeg, .png, .GIF, .JPG, .PNG">
                <i class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <el-image class="el-upload-list__item-thumbnail" :id="file.name" :src="file.url" :preview-src-list="showFileList" :z-index="99999999999" />
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="DialogPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>

                    <span v-if="isDelete" class="el-upload-list__item-delete" @click="deleteImg(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-scrollbar>
          </div>
        </el-tab-pane>
      </el-tabs>

      <span slot="footer" :hidden="refundActiveName=='1'">
        <el-button @click="refundDialogVisible =false" size="small">取消</el-button>
        <el-button type="primary" @click="refundCashierReceiptDialogVisible = true" size="small">打印小票</el-button>
        <el-button type="danger" @click="CancelOrder" size="small" v-if="isCancel&saleOrderDetail.Cancel" v-prevent-click :loading="cancelSaleBillLoading">取消订单</el-button>
        <el-button v-if="isModifyPerformanceCommission&saleOrderDetail.BillStatus == '20'" @click="changePerformancecommission" size="small" type="primary" v-prevent-click :loading="modifyPerformanceCommissionLoading">修改经手人业绩提成</el-button>
      </span>
    </el-dialog>

    <!-- 修改员工业绩 -->
    <el-dialog :visible.sync="changePerformance" width="1380px">
      <span slot="title">销售订单修改经手人业绩提成</span>
      <div v-if="PerformanceInfo" class="font_13" style="height:60vh">
        <el-scrollbar class="el-scrollbar_height">
          <!-- 项目 -->
          <div v-if="PerformanceInfo.Project.length > 0">
            <div v-for="(item, index) in PerformanceInfo.Project" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="5">项目</el-col>
                <el-col :span="2">数量</el-col>
                <el-col :span="4">优惠金额</el-col>
                <el-col :span="3">购买金额</el-col>
                <el-col :span="5">支付金额</el-col>
                <el-col :span="5">欠款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="5">
                      <div>
                        {{item.ProjectName}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                        <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <el-col :span="2">x {{item.Quantity}}</el-col>
                    <el-col :span="4">
                      <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </el-col>
                    <el-col :span="3">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                    <el-col :span="5">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col>
                    <el-col :span="5">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <el-col :span="7">
                      <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="7">
                      <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                        手动改价：
                        <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                        <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                      </span>
                      <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                        卡优惠：
                        <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                      </span>
                    </el-col>
                    <el-col :span="10" :offset="(item.PricePreferentialAmount==0&&item.CardPreferentialAmount ==0)?7:0">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(1,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,10)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="卡抵扣业绩">
                              <span slot="label">
                                卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                  <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣比例提成">
                              <span slot="label">
                                卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                  <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                  <p v-else>卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣固定提成">
                              <span slot="label">
                                卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                  <p v-else>卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送卡抵扣业绩">
                              <span slot="label">
                                赠送卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                  <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣比例提成">
                              <span slot="label">
                                赠送卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                  <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                  <p v-else>赠送卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣固定提成">
                              <span slot="label">
                                赠送卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                  <p v-else>赠送卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 储值卡 -->
          <div v-if="PerformanceInfo.SavingCard.length > 0">
            <div v-for="(item, index) in PerformanceInfo.SavingCard" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="5">储值卡</el-col>
                <el-col :span="2">购买数量</el-col>
                <el-col :span="4">充值金额</el-col>
                <el-col :span="4">赠送金额</el-col>
                <el-col :span="5">支付金额</el-col>
                <el-col :span="4">欠款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="5">
                      <div>
                        {{item.SavingCardName}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                      </div>
                    </el-col>
                    <el-col :span="2">x {{item.Quantity}}</el-col>
                    <el-col :span="4">¥ {{item.TotalAmount | NumFormat}}</el-col>
                    <el-col :span="4">¥ {{ item.LargessAmount | NumFormat}}</el-col>
                    <el-col :span="5">¥ {{item.PayAmount| NumFormat}}</el-col>
                    <el-col :span="4">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <el-col :span="15">
                      <div>
                        <span class="color_red font_12">¥ {{item.Price | NumFormat}}</span>
                        <span class="marlt_10 font_12 color_gray">赠送：¥ {{item.LargessPrice}}</span>
                      </div>
                    </el-col>
                    <el-col :span="9">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(5,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,20)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 时效卡 -->
          <div v-if="PerformanceInfo.TimeCard.length > 0">
            <div v-for="(item, index) in PerformanceInfo.TimeCard" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="5">时效卡</el-col>
                <el-col :span="2">数量</el-col>
                <el-col :span="4">优惠金额</el-col>
                <el-col :span="3">购买金额</el-col>
                <el-col :span="5">支付金额</el-col>
                <el-col :span="5">欠款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="5">
                      <div>
                        {{item.TimeCardName}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                        <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <el-col :span="2">x {{item.Quantity}}</el-col>
                    <el-col :span="4">
                      <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </el-col>
                    <el-col :span="3">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                    <el-col :span="5">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col>
                    <el-col :span="5">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <el-col :span="7">
                      <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="7">
                      <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                        手动改价：
                        <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                        <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                      </span>
                      <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                        卡优惠：
                        <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                      </span>
                    </el-col>
                    <el-col :span="10" :offset="(item.PricePreferentialAmount==0&&item.CardPreferentialAmount ==0)?7:0">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(4,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,30)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="卡抵扣业绩">
                              <span slot="label">
                                卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                  <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣比例提成">
                              <span slot="label">
                                卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                  <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                  <p v-else>卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣固定提成">
                              <span slot="label">
                                卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                  <p v-else>卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送卡抵扣业绩">
                              <span slot="label">
                                赠送卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                  <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣比例提成">
                              <span slot="label">
                                赠送卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                  <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                  <p v-else>赠送卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣固定提成">
                              <span slot="label">
                                赠送卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                  <p v-else>赠送卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 通用次卡 -->
          <div v-if="PerformanceInfo.GeneralCard.length > 0">
            <div v-for="(item, index) in PerformanceInfo.GeneralCard" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="5">通用次卡</el-col>
                <el-col :span="2">数量</el-col>
                <el-col :span="4">优惠金额</el-col>
                <el-col :span="3">购买金额</el-col>
                <el-col :span="5">支付金额</el-col>
                <el-col :span="5">欠款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="5">
                      <div>
                        {{item.GeneralCardName}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                        <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <el-col :span="2">x {{item.Quantity}}</el-col>
                    <el-col :span="4">
                      <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </el-col>
                    <el-col :span="3">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                    <el-col :span="5">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col>
                    <el-col :span="5">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <el-col :span="7">
                      <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="7">
                      <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                        手动改价：
                        <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                        <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                      </span>
                      <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                        卡优惠：
                        <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                      </span>
                    </el-col>
                    <el-col :span="10" :offset="(item.PricePreferentialAmount==0&&item.CardPreferentialAmount ==0)?7:0">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(3,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,40)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="卡抵扣业绩">
                              <span slot="label">
                                卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                  <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣比例提成">
                              <span slot="label">
                                卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                  <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                  <p v-else>卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣固定提成">
                              <span slot="label">
                                卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                  <p v-else>卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送卡抵扣业绩">
                              <span slot="label">
                                赠送卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                  <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣比例提成">
                              <span slot="label">
                                赠送卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                  <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                  <p v-else>赠送卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣固定提成">
                              <span slot="label">
                                赠送卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                  <p v-else>赠送卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 产品 -->
          <div v-if="PerformanceInfo.Product.length > 0">
            <div v-for="(item, index) in PerformanceInfo.Product" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="5">产品</el-col>
                <el-col :span="2">数量</el-col>
                <el-col :span="4">优惠金额</el-col>
                <el-col :span="3">购买金额</el-col>
                <el-col :span="5">支付金额</el-col>
                <el-col :span="5">欠款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="5">
                      <div>
                        {{item.ProductName}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                        <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <el-col :span="2">x {{item.Quantity}}</el-col>
                    <el-col :span="4">
                      <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </el-col>
                    <el-col :span="3">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                    <el-col :span="5">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col>
                    <el-col :span="5">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <el-col :span="7">
                      <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="7">
                      <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                        手动改价：
                        <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                        <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                      </span>
                      <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                        卡优惠：
                        <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                      </span>
                    </el-col>
                    <el-col :span="10" :offset="(item.PricePreferentialAmount==0&&item.CardPreferentialAmount ==0)?7:0">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(2,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,50)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="卡抵扣业绩">
                              <span slot="label">
                                卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                  <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣比例提成">
                              <span slot="label">
                                卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                  <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                  <p v-else>卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣固定提成">
                              <span slot="label">
                                卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                  <p v-else>卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送卡抵扣业绩">
                              <span slot="label">
                                赠送卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                  <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣比例提成">
                              <span slot="label">
                                赠送卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                  <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                  <p v-else>赠送卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣固定提成">
                              <span slot="label">
                                赠送卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                  <p v-else>赠送卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 套餐卡 -->
          <div v-if="PerformanceInfo.PackageCard.length > 0">
            <div v-for="(packageCard, index) in PerformanceInfo.PackageCard" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="5">套餐卡</el-col>
                <el-col :span="2">数量</el-col>
                <el-col :span="4">优惠金额</el-col>
                <el-col :span="3">购买金额</el-col>
                <el-col :span="5">支付金额</el-col>
                <el-col :span="5">欠款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="5">
                      <div>
                        {{packageCard.PackageCardName}}
                        <span v-if="packageCard.Alias">({{packageCard.Alias}})</span>
                        <el-tag v-if="packageCard.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <el-col :span="2">x {{packageCard.Quantity}}</el-col>
                    <el-col :span="4">
                      <span v-if="packageCard.PreferentialTotalAmount<0">+ ¥ {{Math.abs(packageCard.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else-if="packageCard.PreferentialTotalAmount>0">- ¥ {{Math.abs(packageCard.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </el-col>
                    <el-col :span="3">¥ {{ packageCard.IsLargess?0:packageCard.TotalAmount | NumFormat}}</el-col>
                    <el-col :span="5">¥ {{parseFloat(packageCard.PayAmount)+parseFloat(packageCard.CardDeductionTotalAmount) | NumFormat}}</el-col>
                    <el-col :span="5">¥ {{packageCard.ArrearAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <el-col :span="7">
                      <div class="color_red font_12">¥ {{packageCard.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="7">
                      <span class="color_gray font_12" v-if="packageCard.PricePreferentialAmount !=0">
                        手动改价：
                        <span class="color_red" v-if="packageCard.PricePreferentialAmount>0">- ¥ {{packageCard.PricePreferentialAmount | NumFormat}}</span>
                        <span class="color_green" v-else>+ ¥ {{Math.abs(packageCard.PricePreferentialAmount) | NumFormat}}</span>
                      </span>
                      <span class="color_gray font_12" :class="packageCard.PricePreferentialAmount !=0?'marlt_15':''" v-if="packageCard.CardPreferentialAmount>0">
                        卡优惠：
                        <span class="color_red">- ¥ {{parseFloat(packageCard.CardPreferentialAmount) | NumFormat}}</span>
                      </span>
                    </el-col>
                    <el-col :span="10" :offset="(packageCard.PricePreferentialAmount==0&&packageCard.CardPreferentialAmount ==0)?7:0">
                      <span class="color_gray font_12" v-if="packageCard.PayAmount >0">现金金额：¥ {{packageCard.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="packageCard.PayAmount >0?'marlt_15':''" v-if="packageCard.CardDeductionAmount>0">卡抵扣：¥ {{packageCard.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(packageCard.PricePreferentialAmount >0||packageCard.PayAmount >0)?'marlt_15':''" v-if="packageCard.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{packageCard.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in packageCard.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(6,packageCard)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-form class="sale-ModifyPerformanceCommission-Handler" :inline="true" label-position="right" size="mini" label-width="80px">
                    <el-form-item class="padtp_10" v-for="(employee) in handler.Employee" :key="employee.EmployeeID" :label="`${employee.EmployeeName}`">
                      <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changePackageSaleHandlerRate(packageCard,handler,employee)">
                        <template slot="append">%</template>
                      </el-input>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
              <!-- 套餐卡-产品 -->
              <div v-if="packageCard.Product.length > 0">
                <div v-for="(item, index) in packageCard.Product" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="5">套餐卡产品</el-col>
                    <el-col :span="2">数量</el-col>
                    <el-col :span="4">优惠金额</el-col>
                    <el-col :span="3">购买金额</el-col>
                    <el-col :span="5">支付金额</el-col>
                    <el-col :span="5">欠款金额</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="5">
                          <div>
                            {{item.ProductName}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                          </div>
                        </el-col>
                        <el-col :span="2">x {{item.Quantity*packageCard.Quantity}}</el-col>
                        <el-col :span="4">
                          <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </el-col>
                        <el-col :span="3">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                        <el-col :span="5">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col>
                        <el-col :span="5">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <el-col :span="7">
                          <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                        </el-col>
                        <el-col :span="7">
                          <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                            手动改价：
                            <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                            <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                          </span>
                          <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                            卡优惠：
                            <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                          </span>
                        </el-col>
                        <el-col :span="10" :offset="(item.PricePreferentialAmount==0&&item.CardPreferentialAmount ==0)?7:0">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="卡抵扣业绩">
                                    <span slot="label">
                                      卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                        <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣比例提成">
                                    <span slot="label">
                                      卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                        <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                        <p v-else>卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣固定提成">
                                    <span slot="label">
                                      卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                        <p v-else>卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送卡抵扣业绩">
                                    <span slot="label">
                                      赠送卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                        <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣比例提成">
                                    <span slot="label">
                                      赠送卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                        <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                        <p v-else>赠送卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣固定提成">
                                    <span slot="label">
                                      赠送卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                        <p v-else>赠送卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <!-- 套餐卡-项目 -->
              <div v-if="packageCard.Project.length > 0">
                <div v-for="(item, index) in packageCard.Project" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="5">套餐卡项目</el-col>
                    <el-col :span="2">数量</el-col>
                    <el-col :span="4">优惠金额</el-col>
                    <el-col :span="3">购买金额</el-col>
                    <el-col :span="5">支付金额</el-col>
                    <el-col :span="5">欠款金额</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="5">
                          <div>
                            {{item.ProjectName}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                          </div>
                        </el-col>
                        <el-col :span="2">x {{item.Quantity*packageCard.Quantity}}</el-col>
                        <el-col :span="4">
                          <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </el-col>
                        <el-col :span="3">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                        <el-col :span="5">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col>
                        <el-col :span="5">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <el-col :span="7">
                          <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                        </el-col>
                        <el-col :span="7">
                          <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                            手动改价：
                            <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                            <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                          </span>
                          <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                            卡优惠：
                            <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                          </span>
                        </el-col>
                        <el-col :span="10" :offset="(item.PricePreferentialAmount==0&&item.CardPreferentialAmount ==0)?7:0">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="卡抵扣业绩">
                                    <span slot="label">
                                      卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                        <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣比例提成">
                                    <span slot="label">
                                      卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                        <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                        <p v-else>卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣固定提成">
                                    <span slot="label">
                                      卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                        <p v-else>卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送卡抵扣业绩">
                                    <span slot="label">
                                      赠送卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                        <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣比例提成">
                                    <span slot="label">
                                      赠送卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                        <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                        <p v-else>赠送卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣固定提成">
                                    <span slot="label">
                                      赠送卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                        <p v-else>赠送卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <!-- 套餐卡-通用次卡 -->
              <div v-if="packageCard.GeneralCard.length > 0">
                <div v-for="(item, index) in packageCard.GeneralCard" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="5">套餐卡通用次卡</el-col>
                    <el-col :span="2">数量</el-col>
                    <el-col :span="4">优惠金额</el-col>
                    <el-col :span="3">购买金额</el-col>
                    <el-col :span="5">支付金额</el-col>
                    <el-col :span="5">欠款金额</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="5">
                          <div>
                            {{item.GeneralCardName}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                          </div>
                        </el-col>
                        <el-col :span="2">x {{item.Quantity*packageCard.Quantity}}</el-col>
                        <el-col :span="4">
                          <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </el-col>
                        <el-col :span="3">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                        <el-col :span="5">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col>
                        <el-col :span="5">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <el-col :span="7">
                          <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                        </el-col>
                        <el-col :span="7">
                          <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                            手动改价：
                            <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                            <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                          </span>
                          <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                            卡优惠：
                            <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                          </span>
                        </el-col>
                        <el-col :span="10" :offset="(item.PricePreferentialAmount==0&&item.CardPreferentialAmount ==0)?7:0">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <!-- <div v-if="!item.IsLargess"> -->
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="卡抵扣业绩">
                                    <span slot="label">
                                      卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                        <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣比例提成">
                                    <span slot="label">
                                      卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                        <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                        <p v-else>卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣固定提成">
                                    <span slot="label">
                                      卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                        <p v-else>卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送卡抵扣业绩">
                                    <span slot="label">
                                      赠送卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                        <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣比例提成">
                                    <span slot="label">
                                      赠送卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                        <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                        <p v-else>赠送卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣固定提成">
                                    <span slot="label">
                                      赠送卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                        <p v-else>赠送卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <!-- 套餐卡-时效卡 -->
              <div v-if="packageCard.TimeCard.length > 0">
                <div v-for="(item, index) in packageCard.TimeCard" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="5">套餐卡时效卡</el-col>
                    <el-col :span="2">数量</el-col>
                    <el-col :span="4">优惠金额</el-col>
                    <el-col :span="3">购买金额</el-col>
                    <el-col :span="5">支付金额</el-col>
                    <el-col :span="5">欠款金额</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="5">
                          <div>
                            {{item.TimeCardName}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                          </div>
                        </el-col>
                        <el-col :span="2">x {{item.Quantity*packageCard.Quantity}}</el-col>
                        <el-col :span="4">
                          <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </el-col>
                        <el-col :span="3">¥ {{ item.IsLargess?0:item.TotalAmount | NumFormat}}</el-col>
                        <el-col :span="5">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col>
                        <el-col :span="5">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <el-col :span="7">
                          <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                        </el-col>
                        <el-col :span="7">
                          <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                            手动改价：
                            <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                            <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                          </span>
                          <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                            卡优惠：
                            <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                          </span>
                        </el-col>
                        <el-col :span="10" :offset="(item.PricePreferentialAmount==0&&item.CardPreferentialAmount ==0)?7:0">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <!-- <div v-if="!item.IsLargess"> -->
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="卡抵扣业绩">
                                    <span slot="label">
                                      卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                        <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣比例提成">
                                    <span slot="label">
                                      卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                        <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                        <p v-else>卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣固定提成">
                                    <span slot="label">
                                      卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                        <p v-else>卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送卡抵扣业绩">
                                    <span slot="label">
                                      赠送卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                        <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣比例提成">
                                    <span slot="label">
                                      赠送卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                        <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                        <p v-else>赠送卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣固定提成">
                                    <span slot="label">
                                      赠送卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                        <p v-else>赠送卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <!-- 套餐卡-储值卡 -->
              <div v-if="packageCard.SavingCard.length > 0">
                <div v-for="(item, index) in packageCard.SavingCard" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="5">套餐卡储值卡</el-col>
                    <el-col :span="2">购买数量</el-col>
                    <el-col :span="4">充值金额</el-col>
                    <el-col :span="4">赠送金额</el-col>
                    <el-col :span="5">支付金额</el-col>
                    <el-col :span="4">欠款金额</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="5">
                          <div>
                            {{item.SavingCardName}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                          </div>
                        </el-col>
                        <el-col :span="2">x {{item.Quantity*packageCard.Quantity}}</el-col>
                        <el-col :span="4">¥ {{item.TotalAmount | NumFormat}}</el-col>
                        <el-col :span="4">¥ {{ item.LargessAmount | NumFormat}}</el-col>
                        <el-col :span="5">¥ {{item.PayAmount| NumFormat}}</el-col>
                        <el-col :span="4">¥ {{item.ArrearAmount | NumFormat}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <el-col :span="15">
                          <div>
                            <span class="color_red font_12">¥ {{item.Price | NumFormat}}</span>
                            <span class="marlt_10 font_12 color_gray">赠送：¥ {{item.LargessPrice}}</span>
                          </div>
                        </el-col>
                        <el-col :span="9">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <!-- <div v-if="!item.IsLargess"> -->
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changePerformance = false" size="small">取消</el-button>
        <el-button type="primary" @click="saveSaleBill" size="small" v-prevent-click :loading="savePerformanceCommissionLoading">保存</el-button>
      </span>
    </el-dialog>

    <!-- 补欠款 修改员工业绩 -->
    <el-dialog :visible.sync="arrearChangePerformance" width="1380px">
      <span slot="title">补欠款订单修改经手人业绩提成</span>
      <div v-if="PerformanceInfo" class="font_13" style="height:60vh">
        <el-scrollbar class="el-scrollbar_height">
          <!-- 项目 -->
          <div v-if="PerformanceInfo.Project.length > 0">
            <div v-for="(item, index) in PerformanceInfo.Project" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="8">项目</el-col>
                <el-col :span="4">数量</el-col>
                <el-col :span="6">购买金额</el-col>
                <el-col :span="6">补欠款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="8">
                      <div>
                        {{item.ProjectName}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                        <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <el-col :span="4">x {{item.Quantity}}</el-col>
                    <el-col :span="6">¥ {{ item.IsLargess?0:item.AccountTotalAmount | NumFormat}}</el-col>
                    <el-col :span="6">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <!-- <el-col :span="7">
                      <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="7">
                      <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                        手动改价：
                        <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                        <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                      </span>
                      <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                        卡优惠：
                        <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                      </span>
                    </el-col>-->
                    <el-col :span="6" :offset="18">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(1,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,10)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="卡抵扣业绩">
                              <span slot="label">
                                卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                  <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣比例提成">
                              <span slot="label">
                                卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                  <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                  <p v-else>卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣固定提成">
                              <span slot="label">
                                卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                  <p v-else>卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送卡抵扣业绩">
                              <span slot="label">
                                赠送卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                  <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣比例提成">
                              <span slot="label">
                                赠送卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                  <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                  <p v-else>赠送卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣固定提成">
                              <span slot="label">
                                赠送卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                  <p v-else>赠送卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 储值卡 -->
          <div v-if="PerformanceInfo.SavingCard.length > 0">
            <div v-for="(item, index) in PerformanceInfo.SavingCard" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="8">储值卡</el-col>
                <!-- <el-col :span="2">购买数量</el-col> -->
                <el-col :span="8">充值金额</el-col>
                <!-- <el-col :span="4">赠送金额</el-col> -->
                <!-- <el-col :span="5">支付金额</el-col> -->
                <el-col :span="8">补欠款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="8">
                      {{item.SavingCardName}}
                      <span v-if="item.Alias">({{item.Alias}})</span>
                    </el-col>
                    <!-- <el-col :span="2">x {{item.Quantity}}</el-col> -->
                    <el-col :span="8">¥ {{item.AccountTotalAmount | NumFormat}}</el-col>
                    <!-- <el-col :span="4">¥ {{ item.LargessAmount | NumFormat}}</el-col> -->
                    <!-- <el-col :span="5">¥ {{item.PayAmount| NumFormat}}</el-col> -->
                    <el-col :span="8">¥ {{item.TotalAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <!-- <el-col :span="15">
                      <div>
                        <span class="color_red font_12">¥ {{item.Price | NumFormat}}</span>
                        <span class="marlt_10 font_12 color_gray">赠送：¥ {{item.LargessPrice}}</span>
                      </div>
                    </el-col>-->
                    <el-col :span="9" :offset="16">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(5,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,20)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 时效卡 -->
          <div v-if="PerformanceInfo.TimeCard.length > 0">
            <div v-for="(item, index) in PerformanceInfo.TimeCard" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="8">时效卡</el-col>
                <!-- <el-col :span="2">数量</el-col> -->
                <!-- <el-col :span="4">优惠金额</el-col> -->
                <el-col :span="8">购买金额</el-col>
                <!-- <el-col :span="5">支付金额</el-col> -->
                <el-col :span="8">补欠款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="8">
                      <div>
                        {{item.TimeCardName}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                        <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <!-- <el-col :span="2">x {{item.Quantity}}</el-col> -->
                    <!-- <el-col :span="4">
                      <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </el-col>-->
                    <el-col :span="8">¥ {{ item.AccountTotalAmount | NumFormat}}</el-col>
                    <!-- <el-col :span="5">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col> -->
                    <el-col :span="8">¥ {{item.TotalAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <!-- <el-col :span="7">
                      <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="7">
                      <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                        手动改价：
                        <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                        <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                      </span>
                      <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                        卡优惠：
                        <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                      </span>
                    </el-col>-->
                    <el-col :span="8" :offset="16">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(4,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,30)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="卡抵扣业绩">
                              <span slot="label">
                                卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                  <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣比例提成">
                              <span slot="label">
                                卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                  <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                  <p v-else>卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣固定提成">
                              <span slot="label">
                                卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                  <p v-else>卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送卡抵扣业绩">
                              <span slot="label">
                                赠送卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                  <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣比例提成">
                              <span slot="label">
                                赠送卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                  <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                  <p v-else>赠送卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣固定提成">
                              <span slot="label">
                                赠送卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                  <p v-else>赠送卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 通用次卡 -->
          <div v-if="PerformanceInfo.GeneralCard.length > 0">
            <div v-for="(item, index) in PerformanceInfo.GeneralCard" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="8">通用次卡</el-col>
                <!-- <el-col :span="2">数量</el-col> -->
                <!-- <el-col :span="4">优惠金额</el-col> -->
                <el-col :span="8">购买金额</el-col>
                <!-- <el-col :span="5">支付金额</el-col> -->
                <el-col :span="8">补欠款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="8">
                      <div>
                        {{item.GeneralCardName}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                        <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <!-- <el-col :span="2">x {{item.Quantity}}</el-col>
                    <el-col :span="4">
                      <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </el-col>-->
                    <el-col :span="8">¥ {{ item.AccountTotalAmount | NumFormat}}</el-col>
                    <!-- <el-col :span="5">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col> -->
                    <el-col :span="8">¥ {{item.TotalAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <!-- <el-col :span="7">
                      <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="7">
                      <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                        手动改价：
                        <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                        <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                      </span>
                      <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                        卡优惠：
                        <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                      </span>
                    </el-col>-->
                    <el-col :span="8" :offset="16">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(3,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,40)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="卡抵扣业绩">
                              <span slot="label">
                                卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                  <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣比例提成">
                              <span slot="label">
                                卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                  <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                  <p v-else>卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣固定提成">
                              <span slot="label">
                                卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                  <p v-else>卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送卡抵扣业绩">
                              <span slot="label">
                                赠送卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                  <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣比例提成">
                              <span slot="label">
                                赠送卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                  <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                  <p v-else>赠送卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣固定提成">
                              <span slot="label">
                                赠送卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                  <p v-else>赠送卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 产品 -->
          <div v-if="PerformanceInfo.Product.length > 0">
            <div v-for="(item, index) in PerformanceInfo.Product" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="8">产品</el-col>
                <el-col :span="4">数量</el-col>
                <el-col :span="6">购买金额</el-col>
                <el-col :span="6">补欠款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="8">
                      <div>
                        {{item.ProductName}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                        <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <el-col :span="4">x {{item.Quantity}}</el-col>
                    <el-col :span="6">¥ {{ item.IsLargess?0:item.AccountTotalAmount | NumFormat}}</el-col>
                    <el-col :span="6">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <el-col :span="6" :offset="18">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(2,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,50)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="卡抵扣业绩">
                              <span slot="label">
                                卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                  <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣比例提成">
                              <span slot="label">
                                卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                  <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                  <p v-else>卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣固定提成">
                              <span slot="label">
                                卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                  <p v-else>卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送卡抵扣业绩">
                              <span slot="label">
                                赠送卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                  <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣比例提成">
                              <span slot="label">
                                赠送卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                  <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                  <p v-else>赠送卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣固定提成">
                              <span slot="label">
                                赠送卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                  <p v-else>赠送卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 套餐卡 -->
          <div v-if="PerformanceInfo.PackageCard.length > 0">
            <div v-for="(packageCard, index) in PerformanceInfo.PackageCard" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="9">套餐卡</el-col>
                <el-col :span="5">数量</el-col>
                <el-col :span="5">购买金额</el-col>
                <el-col :span="5">补欠款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="9">
                      <div>
                        {{packageCard.PackageCardName}}
                        <span v-if="packageCard.Alias">({{packageCard.Alias}})</span>
                        <el-tag v-if="packageCard.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <el-col :span="5">x {{packageCard.Quantity}}</el-col>
                    <el-col :span="5">¥ {{packageCard.AccountTotalAmount | NumFormat}}</el-col>
                    <el-col :span="5">¥ {{packageCard.TotalAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <!-- <el-col :span="7">
                      <div class="color_red font_12">¥ {{packageCard.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="7">
                      <span class="color_gray font_12" v-if="packageCard.PricePreferentialAmount !=0">
                        手动改价：
                        <span class="color_red" v-if="packageCard.PricePreferentialAmount>0">- ¥ {{packageCard.PricePreferentialAmount | NumFormat}}</span>
                        <span class="color_green" v-else>+ ¥ {{Math.abs(packageCard.PricePreferentialAmount) | NumFormat}}</span>
                      </span>
                      <span class="color_gray font_12" :class="packageCard.PricePreferentialAmount !=0?'marlt_15':''" v-if="packageCard.CardPreferentialAmount>0">
                        卡优惠：
                        <span class="color_red">- ¥ {{parseFloat(packageCard.CardPreferentialAmount) | NumFormat}}</span>
                      </span>
                    </el-col>-->
                    <el-col :span="5" :offset="19">
                      <span class="color_gray font_12" v-if="packageCard.PayAmount >0">现金金额：¥ {{packageCard.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="packageCard.PayAmount >0?'marlt_15':''" v-if="packageCard.CardDeductionAmount>0">卡抵扣：¥ {{packageCard.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(packageCard.PricePreferentialAmount >0||packageCard.PayAmount >0)?'marlt_15':''" v-if="packageCard.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{packageCard.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in packageCard.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(6,packageCard)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-form class="sale-ModifyPerformanceCommission-Handler" :inline="true" label-position="right" size="mini" label-width="80px">
                    <el-form-item class="padtp_10" v-for="(employee) in handler.Employee" :key="employee.EmployeeID" :label="`${employee.EmployeeName}`">
                      <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changePackageSaleHandlerRate(packageCard,handler,employee)">
                        <template slot="append">%</template>
                      </el-input>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
              <!-- 套餐卡-产品 -->
              <div v-if="packageCard.Product.length > 0">
                <div v-for="(item, index) in packageCard.Product" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="9">套餐卡产品</el-col>
                    <el-col :span="5">数量</el-col>
                    <!-- <el-col :span="4">优惠金额</el-col> -->
                    <el-col :span="5">购买金额</el-col>
                    <!-- <el-col :span="5">支付金额</el-col> -->
                    <el-col :span="5">补欠款金额</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="9">
                          <div>
                            {{item.ProductName}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                          </div>
                        </el-col>
                        <el-col :span="5">x {{item.Quantity*packageCard.Quantity}}</el-col>
                        <!-- <el-col :span="4">
                          <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </el-col>-->
                        <el-col :span="5">¥ {{ item.AccountTotalAmount | NumFormat}}</el-col>
                        <!-- <el-col :span="5">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col> -->
                        <el-col :span="5">¥ {{item.TotalAmount | NumFormat}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <!-- <el-col :span="7">
                          <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                        </el-col>
                        <el-col :span="7">
                          <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                            手动改价：
                            <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                            <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                          </span>
                          <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                            卡优惠：
                            <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                          </span>
                        </el-col>-->
                        <el-col :span="5" :offset="19">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="卡抵扣业绩">
                                    <span slot="label">
                                      卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                        <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣比例提成">
                                    <span slot="label">
                                      卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                        <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                        <p v-else>卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣固定提成">
                                    <span slot="label">
                                      卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                        <p v-else>卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送卡抵扣业绩">
                                    <span slot="label">
                                      赠送卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                        <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣比例提成">
                                    <span slot="label">
                                      赠送卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                        <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                        <p v-else>赠送卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣固定提成">
                                    <span slot="label">
                                      赠送卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                        <p v-else>赠送卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <!-- 套餐卡-项目 -->
              <div v-if="packageCard.Project.length > 0">
                <div v-for="(item, index) in packageCard.Project" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="9">套餐卡项目</el-col>
                    <el-col :span="5">数量</el-col>
                    <!-- <el-col :span="4">优惠金额</el-col> -->
                    <el-col :span="5">购买金额</el-col>
                    <!-- <el-col :span="5">支付金额</el-col> -->
                    <el-col :span="5">补欠款金额</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="9">
                          <div>
                            {{item.ProjectName}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                          </div>
                        </el-col>
                        <el-col :span="5">x {{item.Quantity*packageCard.Quantity}}</el-col>
                        <!-- <el-col :span="4">
                          <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </el-col>-->
                        <el-col :span="5">¥ {{ item.AccountTotalAmount | NumFormat}}</el-col>
                        <!-- <el-col :span="5">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col> -->
                        <el-col :span="5">¥ {{item.TotalAmount | NumFormat}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <!-- <el-col :span="7">
                          <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                        </el-col>
                        <el-col :span="7">
                          <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                            手动改价：
                            <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                            <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                          </span>
                          <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                            卡优惠：
                            <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                          </span>
                        </el-col>-->
                        <el-col :span="5" :offset="19">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="卡抵扣业绩">
                                    <span slot="label">
                                      卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                        <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣比例提成">
                                    <span slot="label">
                                      卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                        <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                        <p v-else>卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣固定提成">
                                    <span slot="label">
                                      卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                        <p v-else>卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送卡抵扣业绩">
                                    <span slot="label">
                                      赠送卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                        <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣比例提成">
                                    <span slot="label">
                                      赠送卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                        <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                        <p v-else>赠送卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣固定提成">
                                    <span slot="label">
                                      赠送卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                        <p v-else>赠送卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <!-- 套餐卡-通用次卡 -->
              <div v-if="packageCard.GeneralCard.length > 0">
                <div v-for="(item, index) in packageCard.GeneralCard" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="9">套餐卡通用次卡</el-col>
                    <el-col :span="5">数量</el-col>
                    <!-- <el-col :span="4">优惠金额</el-col> -->
                    <el-col :span="5">购买金额</el-col>
                    <!-- <el-col :span="5">支付金额</el-col> -->
                    <el-col :span="5">补欠款金额</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="9">
                          <div>
                            {{item.GeneralCardName}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                          </div>
                        </el-col>
                        <el-col :span="5">x {{item.Quantity*packageCard.Quantity}}</el-col>
                        <!-- <el-col :span="4">
                          <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </el-col>-->
                        <el-col :span="5">¥ {{ item.AccountTotalAmount | NumFormat}}</el-col>
                        <!-- <el-col :span="5">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col> -->
                        <el-col :span="5">¥ {{item.TotalAmount | NumFormat}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <!-- <el-col :span="7">
                          <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                        </el-col>
                        <el-col :span="7">
                          <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                            手动改价：
                            <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                            <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                          </span>
                          <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                            卡优惠：
                            <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                          </span>
                        </el-col>-->
                        <el-col :span="5" :offset="19">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <!-- <div v-if="!item.IsLargess"> -->
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="卡抵扣业绩">
                                    <span slot="label">
                                      卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                        <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣比例提成">
                                    <span slot="label">
                                      卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                        <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                        <p v-else>卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣固定提成">
                                    <span slot="label">
                                      卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                        <p v-else>卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送卡抵扣业绩">
                                    <span slot="label">
                                      赠送卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                        <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣比例提成">
                                    <span slot="label">
                                      赠送卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                        <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                        <p v-else>赠送卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣固定提成">
                                    <span slot="label">
                                      赠送卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                        <p v-else>赠送卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <!-- 套餐卡-时效卡 -->
              <div v-if="packageCard.TimeCard.length > 0">
                <div v-for="(item, index) in packageCard.TimeCard" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="9">套餐卡时效卡</el-col>
                    <el-col :span="5">数量</el-col>
                    <!-- <el-col :span="4">优惠金额</el-col> -->
                    <el-col :span="5">购买金额</el-col>
                    <!-- <el-col :span="5">支付金额</el-col> -->
                    <el-col :span="5">补欠款金额</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="9">
                          <div>
                            {{item.TimeCardName}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                          </div>
                        </el-col>
                        <el-col :span="5">x {{item.Quantity*packageCard.Quantity}}</el-col>
                        <!-- <el-col :span="4">
                          <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                          <span v-else>¥ 0.00</span>
                        </el-col>-->
                        <el-col :span="5">¥ {{ item.AccountTotalAmount | NumFormat}}</el-col>
                        <!-- <el-col :span="5">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col> -->
                        <el-col :span="5">¥ {{item.TotalAmount | NumFormat}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <!-- <el-col :span="7">
                          <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                        </el-col>
                        <el-col :span="7">
                          <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                            手动改价：
                            <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                            <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                          </span>
                          <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                            卡优惠：
                            <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                          </span>
                        </el-col>-->
                        <el-col :span="5" :offset="19">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <!-- <div v-if="!item.IsLargess"> -->
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="卡抵扣业绩">
                                    <span slot="label">
                                      卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                        <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣比例提成">
                                    <span slot="label">
                                      卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                        <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                        <p v-else>卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣固定提成">
                                    <span slot="label">
                                      卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                        <p v-else>卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送卡抵扣业绩">
                                    <span slot="label">
                                      赠送卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                        <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣比例提成">
                                    <span slot="label">
                                      赠送卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                        <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                        <p v-else>赠送卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣固定提成">
                                    <span slot="label">
                                      赠送卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                        <p v-else>赠送卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <!-- 套餐卡-储值卡 -->
              <div v-if="packageCard.SavingCard.length > 0">
                <div v-for="(item, index) in packageCard.SavingCard" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="8">套餐卡储值卡</el-col>
                    <!-- <el-col :span="2">购买数量</el-col> -->
                    <el-col :span="8">充值金额</el-col>
                    <!-- <el-col :span="5">赠送金额</el-col> -->
                    <!-- <el-col :span="5">支付金额</el-col> -->
                    <el-col :span="8">补欠款金额</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="8">
                          <div>
                            {{item.SavingCardName}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                          </div>
                        </el-col>
                        <!-- <el-col :span="2">x {{item.Quantity*packageCard.Quantity}}</el-col>
                        <el-col :span="4">¥ {{item.TotalAmount | NumFormat}}</el-col>
                        <el-col :span="4">¥ {{ item.LargessAmount | NumFormat}}</el-col>-->
                        <el-col :span="8">¥ {{item.AccountTotalAmount| NumFormat}}</el-col>
                        <el-col :span="8">¥ {{item.TotalAmount | NumFormat}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <!-- <el-col :span="15">
                          <div>
                            <span class="color_red font_12">¥ {{item.Price | NumFormat}}</span>
                            <span class="marlt_10 font_12 color_gray">赠送：¥ {{item.LargessPrice}}</span>
                          </div>
                        </el-col>-->
                        <el-col :span="8" :offset="16">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <!-- <div v-if="!item.IsLargess"> -->
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="arrearChangePerformance = false" size="small">取消</el-button>
        <el-button type="primary" @click="saveSaleBill" size="small" v-prevent-click :loading="savePerformanceCommissionLoading">保存</el-button>
      </span>
    </el-dialog>

    <!-- 退款单 修改员工业绩 -->
    <el-dialog :visible.sync="refundChangePerformance" width="1380px">
      <span slot="title">退单修改经手人业绩提成</span>
      <div v-if="PerformanceInfo" class="font_13" style="height:60vh">
        <el-scrollbar class="el-scrollbar_height">
          <!-- 项目 -->
          <div v-if="PerformanceInfo.Project.length > 0">
            <div v-for="(item, index) in PerformanceInfo.Project" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="6">项目</el-col>
                <el-col :span="3">购买数量</el-col>
                <el-col :span="4">购买金额</el-col>
                <el-col :span="3">退款数量</el-col>
                <el-col :span="8">退款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="6">
                      <div>
                        {{item.ProjectName}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                        <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <el-col :span="3">x {{item.AccountQuantity}}</el-col>
                    <!-- <el-col :span="4">
                      <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </el-col>-->
                    <el-col :span="4">¥ {{ item.IsLargess?0:item.AccountTotalAmount | NumFormat}}</el-col>
                    <el-col :span="3">x {{item.Quantity}}</el-col>
                    <el-col :span="8">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col>
                    <!-- <el-col :span="5">¥ {{item.ArrearAmount | NumFormat}}</el-col> -->
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <!-- <el-col :span="7">
                      <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="7">
                      <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                        手动改价：
                        <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                        <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                      </span>
                      <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                        卡优惠：
                        <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                      </span>
                    </el-col>-->
                    <el-col :span="8" :offset="16">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(1,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,10)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 购买数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="卡抵扣业绩">
                              <span slot="label">
                                卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                  <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣比例提成">
                              <span slot="label">
                                卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                  <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                  <p v-else>卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣固定提成">
                              <span slot="label">
                                卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 购买数量</p>
                                  <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                  <p v-else>卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送卡抵扣业绩">
                              <span slot="label">
                                赠送卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                  <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣比例提成">
                              <span slot="label">
                                赠送卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                  <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                  <p v-else>赠送卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣固定提成">
                              <span slot="label">
                                赠送卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 购买数量</p>
                                  <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                  <p v-else>赠送卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 购买数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 储值卡 -->
          <div v-if="PerformanceInfo.SavingCard.length > 0">
            <div v-for="(item, index) in PerformanceInfo.SavingCard" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="6">储值卡</el-col>
                <el-col :span="4">充值金额</el-col>
                <el-col :span="4">赠送金额</el-col>
                <el-col :span="5">退款金额</el-col>
                <el-col :span="5">退款赠额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="6">
                      <div>
                        {{item.SavingCardName}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                      </div>
                    </el-col>
                    <!-- <el-col :span="2">x {{item.Quantity}}</el-col> -->
                    <el-col :span="4">¥ {{item.AccountTotalAmount | NumFormat}}</el-col>
                    <el-col :span="4">¥ {{ item.AccountLargessAmount | NumFormat}}</el-col>
                    <!-- <el-col :span="5">¥ {{item.PayAmount| NumFormat}}</el-col> -->
                    <el-col :span="5">¥ {{item.TotalAmount | NumFormat}}</el-col>
                    <el-col :span="5">¥ {{ item.LargessAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <!-- <el-col :span="15">
                      <div>
                        <span class="color_red font_12">¥ {{item.Price | NumFormat}}</span>
                        <span class="marlt_10 font_12 color_gray">赠送：¥ {{item.LargessPrice}}</span>
                      </div>
                    </el-col>-->
                    <el-col :span="10" :offset="14">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(5,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,20)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 时效卡 -->
          <div v-if="PerformanceInfo.TimeCard.length > 0">
            <div v-for="(item, index) in PerformanceInfo.TimeCard" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="6">时效卡</el-col>
                <el-col :span="3">购买数量</el-col>
                <el-col :span="4">购买金额</el-col>
                <el-col :span="3">退款数量</el-col>
                <el-col :span="8">退款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="6">
                      <div>
                        {{item.TimeCardName}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                        <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <el-col :span="3">x {{item.Quantity}}</el-col>
                    <el-col :span="4">¥ {{item.AccountTotalAmount | NumFormat}}</el-col>
                    <el-col :span="3">x {{item.Quantity}}</el-col>
                    <el-col :span="8">¥ {{item.TotalAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <!-- <el-col :span="7">
                      <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="7">
                      <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                        手动改价：
                        <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                        <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                      </span>
                      <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                        卡优惠：
                        <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                      </span>
                    </el-col>-->
                    <el-col :span="8" :offset="16">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(4,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,30)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="卡抵扣业绩">
                              <span slot="label">
                                卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                  <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣比例提成">
                              <span slot="label">
                                卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                  <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                  <p v-else>卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣固定提成">
                              <span slot="label">
                                卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                  <p v-else>卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送卡抵扣业绩">
                              <span slot="label">
                                赠送卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                  <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣比例提成">
                              <span slot="label">
                                赠送卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                  <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                  <p v-else>赠送卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣固定提成">
                              <span slot="label">
                                赠送卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                  <p v-else>赠送卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 通用次卡 -->
          <div v-if="PerformanceInfo.GeneralCard.length > 0">
            <div v-for="(item, index) in PerformanceInfo.GeneralCard" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="6">通用次卡</el-col>
                <el-col :span="3">购买卡次数</el-col>
                <el-col :span="4">购买金额</el-col>
                <el-col :span="3">退卡次数</el-col>
                <el-col :span="8">退款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="6">
                      <div>
                        {{item.GeneralCardName}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                        <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <el-col :span="3">x {{item.AccountCardTimes}}</el-col>
                    <!-- <el-col :span="4">
                      <span v-if="item.PreferentialTotalAmount<0">+ ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else-if="item.PreferentialTotalAmount>0">- ¥ {{Math.abs(item.PreferentialTotalAmount) | NumFormat}}</span>
                      <span v-else>¥ 0.00</span>
                    </el-col>-->
                    <el-col :span="4">¥ {{item.AccountTotalAmount | NumFormat}}</el-col>
                    <el-col :span="3">x {{item.CardTimes}}</el-col>
                    <el-col :span="8">¥ {{item.TotalAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <!-- <el-col :span="7">
                      <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="7">
                      <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                        手动改价：
                        <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                        <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                      </span>
                      <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                        卡优惠：
                        <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                      </span>
                    </el-col>-->
                    <el-col :span="8" :offset="16">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(3,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,40)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="卡抵扣业绩">
                              <span slot="label">
                                卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                  <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣比例提成">
                              <span slot="label">
                                卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                  <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                  <p v-else>卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣固定提成">
                              <span slot="label">
                                卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                  <p v-else>卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送卡抵扣业绩">
                              <span slot="label">
                                赠送卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                  <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣比例提成">
                              <span slot="label">
                                赠送卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                  <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                  <p v-else>赠送卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣固定提成">
                              <span slot="label">
                                赠送卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                  <p v-else>赠送卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 产品 -->
          <div v-if="PerformanceInfo.Product.length > 0">
            <div v-for="(item, index) in PerformanceInfo.Product" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="6">产品</el-col>
                <el-col :span="3">购买数量</el-col>
                <el-col :span="4">购买金额</el-col>
                <el-col :span="3">退款数量</el-col>
                <el-col :span="8">退款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="6">
                      <div>
                        {{item.ProductName}}
                        <span v-if="item.Alias">({{item.Alias}})</span>
                        <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <el-col :span="3">x {{item.AccountQuantity}}</el-col>
                    <el-col :span="4">¥ {{ item.IsLargess?0:item.AccountTotalAmount | NumFormat}}</el-col>
                    <el-col :span="3">x {{item.Quantity}}</el-col>
                    <el-col :span="8">¥ {{parseFloat(item.PayAmount)+parseFloat(item.CardDeductionTotalAmount) | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <!-- <el-col :span="7">
                      <div class="color_red font_12">¥ {{item.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="7">
                      <span class="color_gray font_12" v-if="item.PricePreferentialAmount !=0">
                        手动改价：
                        <span class="color_red" v-if="item.PricePreferentialAmount>0">- ¥ {{item.PricePreferentialAmount | NumFormat}}</span>
                        <span class="color_green" v-else>+ ¥ {{Math.abs(item.PricePreferentialAmount) | NumFormat}}</span>
                      </span>
                      <span class="color_gray font_12" :class="item.PricePreferentialAmount !=0?'marlt_15':''" v-if="item.CardPreferentialAmount>0">
                        卡优惠：
                        <span class="color_red">- ¥ {{parseFloat(item.CardPreferentialAmount) | NumFormat}}</span>
                      </span>
                    </el-col>-->
                    <el-col :span="8" :offset="16">
                      <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(2,item)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                    <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="80px">
                        <el-form-item :label="`${employee.EmployeeName}`">
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changeSaleHandlerRate(item,employee,50)">
                            <template slot="append">%</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="16" class="border_left border_right">
                      <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="现金业绩">
                              <span slot="label">
                                现金业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                  <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金比例提成">
                              <span slot="label">
                                现金比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                  <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                  <p v-else>现金比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="现金固定提成">
                              <span slot="label">
                                现金固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                  <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                  <p v-else>现金固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="卡抵扣业绩">
                              <span slot="label">
                                卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                  <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣比例提成">
                              <span slot="label">
                                卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                  <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                  <p v-else>卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="卡抵扣固定提成">
                              <span slot="label">
                                卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                  <p v-else>卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                      <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                            <el-form-item label="赠送卡抵扣业绩">
                              <span slot="label">
                                赠送卡抵扣业绩
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                  <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣比例提成">
                              <span slot="label">
                                赠送卡抵扣比例提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                  <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                  <p v-else>赠送卡抵扣比例参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                        <el-col :span="8">
                          <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                            <el-form-item label="赠送卡抵扣固定提成">
                              <span slot="label">
                                赠送卡抵扣固定提成
                                <el-popover placement="top-start" width="200" trigger="hover">
                                  <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                  <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                  <p v-else>赠送卡抵扣固定参考值：无</p>
                                  <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                </el-popover>
                              </span>
                              <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                <template slot="append">元</template>
                              </el-input>
                            </el-form-item>
                          </el-form>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                      <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                        <el-form-item label="特殊补贴">
                          <span slot="label">
                            特殊补贴
                            <el-popover placement="top-start" width="200" trigger="hover">
                              <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                              <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                              <p v-else>特殊补贴参考值：无</p>
                              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                            </el-popover>
                          </span>
                          <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                            <template slot="append">元</template>
                          </el-input>
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 套餐卡 -->
          <div v-if="PerformanceInfo.PackageCard.length > 0">
            <div v-for="(packageCard, index) in PerformanceInfo.PackageCard" :key="index">
              <el-row class="row_header border_right border_left">
                <el-col :span="6">套餐卡</el-col>
                <el-col :span="3">购买数量</el-col>
                <el-col :span="4">购买金额</el-col>
                <el-col :span="3">退款数量</el-col>
                <el-col :span="8">退款金额</el-col>
              </el-row>
              <el-row>
                <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                  <el-col :span="24">
                    <el-col :span="6">
                      <div>
                        {{packageCard.PackageCardName}}
                        <span v-if="packageCard.Alias">({{packageCard.Alias}})</span>
                        <el-tag v-if="packageCard.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                      </div>
                    </el-col>
                    <el-col :span="3">x {{packageCard.Quantity}}</el-col>
                    <el-col :span="4">¥ {{packageCard.AccountTotalAmount | NumFormat}}</el-col>
                    <el-col :span="3">¥ {{ packageCard.Quantity}}</el-col>
                    <el-col :span="8">¥ {{packageCard.TotalAmount | NumFormat}}</el-col>
                  </el-col>
                  <el-col :span="24" class="martp_5">
                    <!-- <el-col :span="7">
                      <div class="color_red font_12">¥ {{packageCard.Price | NumFormat}}</div>
                    </el-col>
                    <el-col :span="7">
                      <span class="color_gray font_12" v-if="packageCard.PricePreferentialAmount !=0">
                        手动改价：
                        <span class="color_red" v-if="packageCard.PricePreferentialAmount>0">- ¥ {{packageCard.PricePreferentialAmount | NumFormat}}</span>
                        <span class="color_green" v-else>+ ¥ {{Math.abs(packageCard.PricePreferentialAmount) | NumFormat}}</span>
                      </span>
                      <span class="color_gray font_12" :class="packageCard.PricePreferentialAmount !=0?'marlt_15':''" v-if="packageCard.CardPreferentialAmount>0">
                        卡优惠：
                        <span class="color_red">- ¥ {{parseFloat(packageCard.CardPreferentialAmount) | NumFormat}}</span>
                      </span>
                    </el-col>-->
                    <el-col :span="8" :offset="16">
                      <span class="color_gray font_12" v-if="packageCard.PayAmount >0">现金金额：¥ {{packageCard.PayAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="packageCard.PayAmount >0?'marlt_15':''" v-if="packageCard.CardDeductionAmount>0">卡抵扣：¥ {{packageCard.CardDeductionAmount | NumFormat}}</span>
                      <span class="color_gray font_12" :class="(packageCard.PricePreferentialAmount >0||packageCard.PayAmount >0)?'marlt_15':''" v-if="packageCard.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{packageCard.CardDeductionLargessAmount | NumFormat}}</span>
                    </el-col>
                  </el-col>
                </el-col>
              </el-row>
              <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in packageCard.SaleBillHandler" :key="handler.SaleHandlerID">
                <el-col :span="2" class="padtp_10 padbm_10">
                  <el-link class="font_12 line_26" type="primary" :underline="false" @click="employeeHandleClick(6,packageCard)">{{handler.SaleHandlerName}}</el-link>
                </el-col>
                <el-col :span="22" class="border_left">
                  <el-form class="sale-ModifyPerformanceCommission-Handler" :inline="true" label-position="right" size="mini" label-width="80px">
                    <el-form-item class="padtp_10" v-for="(employee) in handler.Employee" :key="employee.EmployeeID" :label="`${employee.EmployeeName}`">
                      <el-input type="number" class="input_type" style=" width:100px" v-model="employee.Scale" v-enter-number2 @input="changePackageSaleHandlerRate(packageCard,handler,employee)">
                        <template slot="append">%</template>
                      </el-input>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
              <!-- 套餐卡-产品 -->
              <div v-if="packageCard.Product.length > 0">
                <div v-for="(item, index) in packageCard.Product" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="6">套餐卡产品</el-col>
                    <el-col :span="3">购买数量</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退款数量</el-col>
                    <el-col :span="8">退款金额</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="6">
                          <div>
                            {{item.ProductName}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                          </div>
                        </el-col>
                        <el-col :span="3">x {{item.AccountQuantity}}</el-col>
                        <el-col :span="4">¥ {{item.AccountTotalAmount | NumFormat}}</el-col>
                        <el-col :span="3">x {{item.Quantity}}</el-col>
                        <el-col :span="8">¥ {{item.TotalAmount | NumFormat}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <el-col :span="8" :offset="16">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="卡抵扣业绩">
                                    <span slot="label">
                                      卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                        <p>注：卡抵���业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣比例提成">
                                    <span slot="label">
                                      卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                        <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                        <p v-else>卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣固定提成">
                                    <span slot="label">
                                      卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                        <p v-else>卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送卡抵扣业绩">
                                    <span slot="label">
                                      赠送卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                        <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣比例提成">
                                    <span slot="label">
                                      赠送卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                        <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                        <p v-else>赠送卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣固定提成">
                                    <span slot="label">
                                      赠送卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                        <p v-else>赠送卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <!-- 套餐卡-项目 -->
              <div v-if="packageCard.Project.length > 0">
                <div v-for="(item, index) in packageCard.Project" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="6">套餐卡项目</el-col>
                    <el-col :span="3">购买数量</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退款数量</el-col>
                    <el-col :span="8">退款金额</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="6">
                          <div>
                            {{item.ProjectName}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                          </div>
                        </el-col>
                        <el-col :span="3">x {{item.AccountQuantity}}</el-col>
                        <el-col :span="4">¥ {{item.AccountTotalAmount | NumFormat}}</el-col>
                        <el-col :span="3">x {{ item.Quantity}}</el-col>
                        <el-col :span="8">¥ {{item.TotalAmount | NumFormat}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <el-col :span="8" :offset="16">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="卡抵扣业绩">
                                    <span slot="label">
                                      卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                        <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣比例提成">
                                    <span slot="label">
                                      卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                        <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                        <p v-else>卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣固定提成">
                                    <span slot="label">
                                      卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                        <p v-else>卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送卡抵扣业绩">
                                    <span slot="label">
                                      赠送卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                        <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣比例提成">
                                    <span slot="label">
                                      赠送卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                        <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                        <p v-else>赠送卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣固定提成">
                                    <span slot="label">
                                      赠送卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                        <p v-else>赠送卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <!-- 套餐卡-通用次卡 -->
              <div v-if="packageCard.GeneralCard.length > 0">
                <div v-for="(item, index) in packageCard.GeneralCard" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="6">套餐卡通用次卡</el-col>
                    <el-col :span="3">购买卡次数</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退卡次数</el-col>
                    <el-col :span="8">退款金额</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="6">
                          <div>
                            {{item.GeneralCardName}}
                            <span v-if="item.Alias">({{item.Alias}})</span>
                            <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                          </div>
                        </el-col>
                        <el-col :span="3">x {{item.AccountCardTimes}}</el-col>
                        <el-col :span="4">¥ {{item.AccountTotalAmount | NumFormat}}</el-col>
                        <el-col :span="3">x {{item.CardTimes}}</el-col>
                        <el-col :span="8">¥ {{item.TotalAmount | NumFormat}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <el-col :span="8" :offset="16">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="卡抵扣业绩">
                                    <span slot="label">
                                      卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                        <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣比例提成">
                                    <span slot="label">
                                      卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                        <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                        <p v-else>卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣固定提成">
                                    <span slot="label">
                                      卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                        <p v-else>卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送卡抵扣业绩">
                                    <span slot="label">
                                      赠送卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                        <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣比例提成">
                                    <span slot="label">
                                      赠送卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                        <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                        <p v-else>赠送卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣固定提成">
                                    <span slot="label">
                                      赠送卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                        <p v-else>赠送卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <!-- 套餐卡-时效卡 -->
              <div v-if="packageCard.TimeCard.length > 0">
                <div v-for="(item, index) in packageCard.TimeCard" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="6">套餐卡时效卡</el-col>
                    <el-col :span="3">购买数量</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退款数量</el-col>
                    <el-col :span="8">退款金额</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="6">
                          {{item.TimeCardName}}
                          <span v-if="item.Alias">({{item.Alias}})</span>
                          <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                        </el-col>
                        <el-col :span="3">x {{item.Quantity}}</el-col>
                        <el-col :span="4">¥ {{item.AccountTotalAmount | NumFormat}}</el-col>
                        <el-col :span="3">x {{item.Quantity}}</el-col>
                        <el-col :span="8">¥ {{item.TotalAmount | NumFormat}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <el-col :span="8" :offset="16">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="item.PayAmount >0?'marlt_15':''" v-if="item.CardDeductionAmount>0">卡抵扣：¥ {{item.CardDeductionAmount | NumFormat}}</span>
                          <span class="color_gray font_12" :class="(item.PricePreferentialAmount >0||item.PayAmount >0)?'marlt_15':''" v-if="item.CardDeductionLargessAmount>0">赠送卡抵扣：¥ {{item.CardDeductionLargessAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="卡抵扣业绩">
                                    <span slot="label">
                                      卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣业绩 = 卡抵扣金额金额 x 业绩占比</p>
                                        <p>注：卡抵扣业绩比例参考值和卡抵扣业绩固定参考值没有设置(或无),则没有卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣比例提成">
                                    <span slot="label">
                                      卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣比例提成 = 卡抵扣业绩 x 卡抵扣比例</p>
                                        <p v-if="employee.SavingCardRate!=null">卡抵扣比例参考值：{{employee.SavingCardRate | NumFormat}}%</p>
                                        <p v-else>卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="卡抵扣固定提成">
                                    <span slot="label">
                                      卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>卡抵扣固定提成 = （卡抵扣业绩 ÷ 购买金额）x 卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardFixed!=null">卡抵扣固定参考值：¥ {{employee.SavingCardFixed | NumFormat}}</p>
                                        <p v-else>卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                            <el-row v-if="item.CardDeductionLargessAmount>0" class="padtp_10 padrt_10 border_top">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="赠送卡抵扣业绩">
                                    <span slot="label">
                                      赠送卡抵扣业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣业绩 = 赠送卡抵扣金额 x 业绩占比</p>
                                        <p>注：赠送卡抵扣业绩比例参考值和赠送卡抵扣业绩固定参考值没有设置(或无),则没有赠送卡抵扣业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.SavingCardLargessPerformance">
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣比例提成">
                                    <span slot="label">
                                      赠送卡抵扣比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣比例提成 = 赠送卡抵扣业绩 x 赠送卡抵扣比例</p>
                                        <p v-if="employee.SavingCardLargessRate!=null">赠送卡抵扣比例参考值：{{employee.SavingCardLargessRate | NumFormat}}%</p>
                                        <p v-else>赠送卡抵扣比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="赠送卡抵扣固定提成">
                                    <span slot="label">
                                      赠送卡抵扣固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>赠送卡抵扣固定提成 = （赠送卡抵扣业绩 ÷ 购买金额）x 赠送卡抵扣固定 x 数量</p>
                                        <p v-if="employee.SavingCardLargessFixed!=null">赠送卡抵扣固定参考值：¥ {{employee.SavingCardLargessFixed | NumFormat}}</p>
                                        <p v-else>赠送卡抵扣固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SavingCardLargessFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <!-- 套餐卡-储值卡 -->
              <div v-if="packageCard.SavingCard.length > 0">
                <div v-for="(item, index) in packageCard.SavingCard" :key="index">
                  <el-row class="row_header_package_detail border_right border_left">
                    <el-col :span="8">套餐卡储值卡</el-col>
                    <el-col :span="8">{{item.IsLargess?'赠送金额':'充值金额'}}</el-col>
                    <el-col :span="8">{{item.IsLargess?'退赠送额':'退款金额'}}</el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24" class="pad_10 border_right border_left border_bottom">
                      <el-col :span="24">
                        <el-col :span="8">
                          {{item.SavingCardName}}
                          <span v-if="item.Alias">({{item.Alias}})</span>
                          <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                        </el-col>
                        <el-col :span="8">¥ {{item.IsLargess?(item.LargessAmount | NumFormat) :(item.AccountTotalAmount | NumFormat)}}</el-col>
                        <el-col :span="8">¥ {{item.IsLargess?(item.LargessPrice | NumFormat):(item.TotalAmount | NumFormat)}}</el-col>
                      </el-col>
                      <el-col :span="24" class="martp_5">
                        <!-- <el-col :span="15">
                          <div>
                            <span class="color_red font_12">¥ {{item.Price | NumFormat}}</span>
                            <span class="marlt_10 font_12 color_gray">赠送：¥ {{item.LargessPrice}}</span>
                          </div>
                        </el-col>-->
                        <el-col :span="8" :offset="16">
                          <span class="color_gray font_12" v-if="item.PayAmount >0">现金金额：¥ {{item.PayAmount | NumFormat}}</span>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                  <div>
                    <el-row class="padlt_10 padrt_10 border_right border_left border_bottom font_12" v-for="handler in item.SaleBillHandler" :key="handler.SaleHandlerID">
                      <el-col :span="2" class="padtp_10 padbm_10 font_12 line_26">{{handler.SaleHandlerName}}</el-col>
                      <el-col :span="22" class="border_left">
                        <el-row v-for="(employee,index) in handler.Employee" :key="employee.EmployeeID">
                          <el-col :span="4" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px" label-suffix=":">
                              <el-form-item :label="`${employee.EmployeeName}`">{{employee.Scale}}%</el-form-item>
                            </el-form>
                          </el-col>
                          <el-col :span="16" class="border_left border_right">
                            <el-row v-if="item.PayAmount>0" class="padtp_10 padrt_10" :class="index!=0?'border_top':''">
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="120px">
                                  <el-form-item label="现金业绩">
                                    <span slot="label">
                                      现金业绩
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金业绩 = 现金付款金额 x 业绩占比</p>
                                        <p>注：现金比例参考值和现金固定参考值没有设置(或无),则没有现金业绩</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:120px" v-model="employee.PayPerformance" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金比例提成">
                                    <span slot="label">
                                      现金比例提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金比例提成 = 现金业绩 x 现金比例</p>
                                        <p v-if="employee.PayRate!=null">现金比例参考值：{{employee.PayRate | NumFormat}}%</p>
                                        <p v-else>现金比例参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayRateCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                              <el-col :span="8">
                                <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="150px">
                                  <el-form-item label="现金固定提成">
                                    <span slot="label">
                                      现金固定提成
                                      <el-popover placement="top-start" width="200" trigger="hover">
                                        <p>现金固定提成 = （现金业绩 ÷ 购买金额）x 现金固定 x 数量</p>
                                        <p v-if="employee.PayFixed!=null">现金固定参考值：¥ {{employee.PayFixed | NumFormat}}</p>
                                        <p v-else>现金固定参考值：无</p>
                                        <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                      </el-popover>
                                    </span>
                                    <el-input type="number" class="input_type" style=" width:100px" v-model="employee.PayFixedCommission" v-enter-number2>
                                      <template slot="append">元</template>
                                    </el-input>
                                  </el-form-item>
                                </el-form>
                              </el-col>
                            </el-row>
                          </el-col>
                          <el-col v-if="!item.IsLargess" :span="4" class="padtp_10" :class="index!=0?'border_top':''">
                            <el-form class="sale-ModifyPerformanceCommission-Handler" label-position="right" size="mini" label-width="90px">
                              <el-form-item label="特殊补贴">
                                <span slot="label">
                                  特殊补贴
                                  <el-popover placement="top-start" width="200" trigger="hover">
                                    <p>特殊补贴 = （支付金额 ÷ 购买金额）x 特殊补贴 x 数量</p>
                                    <p v-if="employee.SpecialBenefit!=null">特殊补贴参考值：¥ {{employee.SpecialBenefit | NumFormat}}</p>
                                    <p v-else>特殊补贴参考值：无</p>
                                    <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
                                  </el-popover>
                                </span>
                                <el-input type="number" class="input_type" style=" width:100px" v-model="employee.SpecialBenefitCommission" v-enter-number2>
                                  <template slot="append">元</template>
                                </el-input>
                              </el-form-item>
                            </el-form>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refundChangePerformance = false" size="small">取消</el-button>
        <el-button type="primary" @click="saveSaleBill" size="small" v-prevent-click :loading="savePerformanceCommissionLoading">保存</el-button>
      </span>
    </el-dialog>

    <!--经手人-->
    <el-dialog title="经手人" :visible.sync="beauticianVisible" width="800px">
      <div>
        <el-row class="padbm_10">
          <el-col :span="8">
            <el-input placeholder="请输入员工编号、姓名" prefix-icon="el-icon-search" v-model="handlerName" size="small" clearable></el-input>
          </el-col>
        </el-row>
        <el-tabs v-model="tabHandle">
          <el-tab-pane :label="handler.Name" :name="`${index}`" v-for="(handler,index) in handlerList" :key="index">
            <el-row style="max-height: 300px;overflow-y: auto">
              <el-col :span="12" v-for="item in handler.Employee.filter(item => !handlerName || item.EmployeeName.toLowerCase().includes(handlerName.toLowerCase()) || item.EmployeeID.toLowerCase().includes(handlerName.toLowerCase())) " :key="item.EmployeeID" class="marbm_10 dis_flex flex_y_center">
                <el-checkbox v-model="item.Checked" @change="handlerCheckedChange(handler.Employee,item)">
                  <span class="marrt_10">{{item.EmployeeName}} [{{item.EmployeeID}}]</span>
                </el-checkbox>
                <el-input placeholder v-model="item.Discount" style="width: 120px" type="number" size="mini" min="0" max="100" v-enter-number @input="handlerPercentChange(handler.Employee,item)">
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="beauticianVisible = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submitHandleClick">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改备注弹框 -->
    <el-dialog width="30%" :visible.sync="innerVisible">
      <span slot="title" class="text_center">修改销售单备注</span>
      <el-input type="textarea" :rows="4" v-model="Remark" placeholder="请输入备注内容"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="updateRemarkClick" size="small">保存</el-button>
      </span>
    </el-dialog>

    <!-- 还款记录弹窗 -->
    <el-dialog width="50%" title="还款记录" :visible.sync="backMoneyVisible" append-to-body>
      <el-table :data="backMoneyTable" style="width:100%" size="small">
        <el-table-column prop="ID" label="还款单号"></el-table-column>
        <el-table-column prop="Amount" label="还款金额"></el-table-column>
        <el-table-column prop="EntityName" label="收银员"></el-table-column>
        <el-table-column prop="EmployeeName" label="还款门店"></el-table-column>
        <el-table-column prop="BillDate" label="还款时间"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="backMoneyVisible = false">关闭</el-button>
      </span>
    </el-dialog>

    <!-- 打印小票 销售订单 -->
    <el-dialog :visible.sync="cashierReceiptDialogVisible" width="300px">
      <span slot="title" class="font_14 color_333">打印小票</span>
      <div v-if="saleOrderDetail && userInfo">
        <el-row>
          <el-col :span="24">
            <saleCashierReceipt :saleOrderDetail="saleOrderDetail" :userInfo="userInfo" :cashierReceipt="cashierReceipt"></saleCashierReceipt>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cashierReceiptDialogVisible = false;" size="small" :disabled="modalLoading">取 消</el-button>
        <el-button type="primary" @click="submitPrint" :loading="modalLoading" v-prevent-click size="small">打印</el-button>
      </div>
    </el-dialog>

    <!-- 打印小票 销售 退 订单 -->
    <el-dialog :visible.sync="refundCashierReceiptDialogVisible" width="300px">
      <span slot="title" class="font_14 color_333">打印小票</span>
      <div v-if="saleOrderDetail && userInfo">
        <el-row>
          <el-col :span="24">
            <el-scrollbar class="el-scrollbar_height" style="height:500px">
              <div class="marrt_10">
                <div class="dis_flex">
                  <span class="flex_box text_center font_16" style="line-height:32px">{{userInfo.EntityName}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">退款信息</span>
                </el-divider>
                <!-- 订单信息 -->
                <receiptInfo :orderInfo="saleOrderDetail" :receiptConf="cashierReceipt"> </receiptInfo>

                <el-divider> <span class="font_12 color_gray">退款明细</span></el-divider>
                <template v-for="(item,index) in saleOrderDetail.Project">
                  <div :key="index + 'Project' + item.ProjectID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1}} {{item.ProjectName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyAmount && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundableAmount  && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.IsLargess?'0.00':item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.SavingCard">
                  <div :key="index + 'SavingCard' + item.SavingCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">{{index+1+saleOrderDetail.Project.length}} {{item.SavingCardName}}</span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{(item.Amount/item.Quantity || 0).toFixed(2) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">充值金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(item.AccountTotalAmount || 0).toFixed(2) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex" v-if="item.AccountLargessAmount > 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(item.AccountLargessAmount || 0).toFixed(2) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Amount | NumFormat}}</span>
                    </div>

                    <div class="dis_flex" v-if="item.LargessAmount > 0">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款赠额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.LargessAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.TimeCard">
                  <div :key="index+'TimeCard'+item.TimeCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length}} {{item.TimeCardName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyAmount  && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundableAmount  && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.IsLargess?'0.00':item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.GeneralCard">
                  <div :key="index+'GeneralCard'+item.GeneralCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length}} {{item.GeneralCardName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购卡次数</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.AccountCardTimes}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyAmount  && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退卡次数</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.CardTimes}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundableAmount  && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.IsLargess?'0.00':item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.Product">
                  <div :key="index+'Product'+item.ProductID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length+saleOrderDetail.GeneralCard.length}} {{item.ProductName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity  " class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.AccountQuantity}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyAmount  && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundBuyQuantity  " class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleRefundableAmount  && !item.IsLargess" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.IsLargess?'0.00':item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.PackageCard">
                  <div :key="index+'PackageCard'+item.PackageCardID">
                    <!-- 套餐卡项目 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardProject" :key="Pindex + 'PProjectID' + packItem.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">{{Pindex+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length+saleOrderDetail.GeneralCard.length + saleOrderDetail.Product.length}} {{packItem.Name}}</span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.AccountQuantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount | NumFormat}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundableAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡储值卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardSavingCard" :key="Pindex + 'PSavingCardID' + packItem.SavingCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length
                          +saleOrderDetail.Product.length}} {{packItem.Name}}
                          <span class="font_12" size="mini" v-if="packItem.IsLargess">(赠)</span>
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">充值金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(packItem.AccountTotalAmount || 0).toFixed(2) | NumFormat}}</span>
                      </div>
                      <div class="dis_flex" v-if="packItem.AccountLargessAmount > 0">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(packItem.AccountLargessAmount || 0).toFixed(2) | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.Amount | NumFormat}}</span>
                      </div>

                      <div class="dis_flex" v-if="item.LargessAmount > 0">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款赠额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.LargessAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡时效卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardTimeCard" :key="Pindex + 'PTimeCardID' + packItem.TimeCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length
                          +saleOrderDetail.Product.length}} {{packItem.Name}}
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.PackageQuantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount | NumFormat}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundableAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡通用次卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardGeneralCard" :key="Pindex + 'PGeneralCardID' + packItem.GeneralCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length
                          +saleOrderDetail.Product.length}} {{packItem.Name}}
                          <span class="font_12" size="mini" v-if="packItem.IsLargess">(赠)</span>
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购卡次数</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.AccountCardTimes}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount | NumFormat}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退卡次数</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.CardTimes}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundableAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡产品 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardProduct" :key="Pindex + 'PProduct' + packItem.ProductID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +item.PackageCardGoods.PackageCardGeneralCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length
                          + saleOrderDetail.Product.length}} {{packItem.Name}}
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.AccountQuantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount | NumFormat}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundableAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">可退金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.TotalAmount + item.PricePreferentialAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 赠送 -->
                    <!-- 套餐卡项目 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardLargessGoods.PackageCardProject" :key="Pindex + 'PProjectID' + packItem.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length
                          +saleOrderDetail.Product.length
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +item.PackageCardGoods.PackageCardGeneralCard.length
                          +item.PackageCardGoods.PackageCardProduct.length
                          }} {{packItem.Name}}【赠】
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.AccountQuantity}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡储值卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardLargessGoods.PackageCardSavingCard" :key="Pindex + 'PSavingCardID' + packItem.SavingCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length
                          +saleOrderDetail.Product.length
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +item.PackageCardGoods.PackageCardGeneralCard.length
                          +item.PackageCardGoods.PackageCardProduct.length
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          }} {{packItem.Name}}【赠】
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>

                      <div class="dis_flex" v-if="packItem.AccountLargessAmount > 0">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(packItem.AccountLargessAmount || 0).toFixed(2) | NumFormat}}</span>
                      </div>

                      <div class="dis_flex" v-if="packItem.TotalPrice > 0">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款赠额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.TotalPrice | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡时效卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardLargessGoods.PackageCardTimeCard" :key="Pindex + 'PTimeCardID' + packItem.TimeCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          +item.PackageCardLargessGoods.PackageCardSavingCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length
                          +saleOrderDetail.Product.length
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +item.PackageCardGoods.PackageCardGeneralCard.length
                          +item.PackageCardGoods.PackageCardProduct.length
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          +item.PackageCardLargessGoods.PackageCardSavingCard.length
                          }} {{packItem.Name}}【赠】
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.PackageQuantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡通用次卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardLargessGoods.PackageCardGeneralCard" :key="Pindex + 'PGeneralCardID' + packItem.GeneralCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          +item.PackageCardLargessGoods.PackageCardSavingCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length
                          +saleOrderDetail.Product.length
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +item.PackageCardGoods.PackageCardGeneralCard.length
                          +item.PackageCardGoods.PackageCardProduct.length
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          +item.PackageCardLargessGoods.PackageCardSavingCard.length
                          +item.PackageCardLargessGoods.PackageCardTimeCard.length
                          }} {{packItem.Name}}【赠】
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠卡次数</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.AccountCardTimes}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退卡次数</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.CardTimes}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡产品 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardLargessGoods.PackageCardProduct" :key="Pindex + 'PProduct' + packItem.ProductID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          +item.PackageCardLargessGoods.PackageCardSavingCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length
                          +saleOrderDetail.Product.length
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +item.PackageCardGoods.PackageCardGeneralCard.length
                          +item.PackageCardGoods.PackageCardProduct.length
                          +item.PackageCardLargessGoods.PackageCardProject.length
                          +item.PackageCardLargessGoods.PackageCardSavingCard.length
                          +item.PackageCardLargessGoods.PackageCardTimeCard.length
                          +item.PackageCardLargessGoods.PackageCardGeneralCard.length
                          }} {{packItem.Name}}【赠】
                        </span>
                        <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">赠送数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.AccountQuantity}}</span>
                      </div>

                      <div v-if="cashierReceipt.SaleRefundBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">退款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.IsLargess?'0.00':packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">合计</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{saleOrderDetail.TotalAmount | NumFormat}}</span>
                </div>
                <div v-if="saleOrderDetail.SaleBillPay && saleOrderDetail.SaleBillPay.length > 0 " class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">付款：</span>
                  <div class="flex_box">
                    <div class="dis_flex" v-for="pay in saleOrderDetail.SaleBillPay" :key="pay.ID + 'pay'">
                      <span class="flex_box color_gray line_height_24">{{pay.Name}}</span>
                      <span class="flex_box text_right line_height_24">¥ {{pay.Amount | NumFormat}}</span>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div v-if="saleOrderDetail.SaleBillPaySavingCardDeduction && saleOrderDetail.SaleBillPaySavingCardDeduction.length > 0" class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">卡抵扣：</span>
                  <div class="flex_box">
                    <div class="dis_flex" v-for="cardPay in saleOrderDetail.SaleBillPaySavingCardDeduction" :key="cardPay.ID + 'cardPay'">
                      <span class="flex_box color_gray line_height_24">{{cardPay.Name}}</span>
                      <span class="flex_box text_right line_height_24">¥ {{cardPay.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </div>
                <div class="dis_flex" v-if="saleOrderDetail.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">手动改价</span>
                  <span class="flex_box font_12 text_right line_height_24" v-if="saleOrderDetail.PricePreferentialAmount>0">-￥{{saleOrderDetail.PricePreferentialAmount | NumFormat}}</span>
                  <span class="flex_box font_12 text_right line_height_24" v-else>+￥{{mathAbsData(saleOrderDetail.PricePreferentialAmount) | NumFormat}}</span>
                </div>
                <div class="dis_flex" v-if="saleOrderDetail.CardPreferentialAmount>0 && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">卡优惠</span>
                  <span class="flex_box font_12 text_right line_height_24">-￥{{saleOrderDetail.CardPreferentialAmount | NumFormat}}</span>
                </div>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex flex_dir_column font_14 font_weight_600 flex_y_center color_999 padbm_10">
                  <span>{{cashierReceipt.WriteTextFirst}}</span>
                  <span>{{cashierReceipt.WriteTextSecond}}</span>
                </div>

                <div class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">签字：</span>
                </div>
              </div>
            </el-scrollbar>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="refundCashierReceiptDialogVisible = false;" size="small" :disabled="modalLoading">取 消</el-button>
        <el-button type="primary" @click="printRefundSaleBillContent" :loading="modalLoading" v-prevent-click size="small">打印</el-button>
      </div>
    </el-dialog>

    <!-- 打印小票 欠尾款 销售订单 -->
    <el-dialog :visible.sync="arrearCashierReceiptDialogVisible" width="300px">
      <span slot="title" class="font_14 color_333">打印小票</span>
      <div v-if="saleOrderDetail && userInfo">
        <el-row>
          <el-col :span="24">
            <el-scrollbar class="el-scrollbar_height" style="height:500px">
              <div class="marrt_10">
                <div class="dis_flex">
                  <span class="flex_box text_center font_16" style="line-height:32px">{{userInfo.EntityName}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">补尾款信息</span>
                </el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">订单编号</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{saleOrderDetail.ID}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">下单时间</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{saleOrderDetail.BillDate}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员姓名</span>
                  <span class="flex_box font_12 text_right line_height_24">{{cashierReceipt.NameEncrypt?formatName(saleOrderDetail.Name) : saleOrderDetail.Name}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员手机号</span>
                  <span class="flex_box font_12 text_right line_height_24">{{cashierReceipt.MobileEncrypt?formatPhone(saleOrderDetail.PhoneNumber):saleOrderDetail.PhoneNumber}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">收银员</span>
                  <span class="flex_box font_12 text_right line_height_24">{{saleOrderDetail.EmployeeName}}</span>
                </div>
                <div v-if="cashierReceipt.EntityAddress" class="dis_flex">
                  <span class="flex_box6 color_gray text-left line_height_24">地址：</span>
                  <span class="flex_box font_12 text_right line_height_24">{{saleOrderDetail.AddressDetail}}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">补尾款明细</span>
                </el-divider>

                <template v-for="(item,index) in saleOrderDetail.Project">
                  <div :key="index + 'Project' + item.ProjectID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1}} {{item.ProjectName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                    </div>

                    <div v-if="cashierReceipt.SaleArrearBuyAmount" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount | NumFormat}}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleArrearBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.TotalAmount | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleArrearBalanceAmount" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.BalanceArrearAmount | NumFormat}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.SavingCard">
                  <div :key="index + 'SavingCard' + item.SavingCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">{{index+1+saleOrderDetail.Project.length}} {{item.SavingCardName}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">充值金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(item.AccountTotalAmount || 0).toFixed(2) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.Amount | NumFormat}}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleArrearBalanceAmount" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.BalanceArrearAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.TimeCard">
                  <div :key="index+'TimeCard'+item.TimeCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length}} {{item.TimeCardName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <!-- <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span> -->
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount | NumFormat}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.TotalAmount | NumFormat}}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleArrearBalanceAmount" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.BalanceArrearAmount | NumFormat}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.GeneralCard">
                  <div :key="index+'GeneralCard'+item.GeneralCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length}} {{item.GeneralCardName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <!-- <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span> -->
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount | NumFormat}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.TotalAmount | NumFormat}}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleArrearBalanceAmount" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.BalanceArrearAmount | NumFormat}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.Product">
                  <div :key="index+'Product'+item.ProductID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length+saleOrderDetail.GeneralCard.length}} {{item.ProductName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <!-- <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span> -->
                    </div>
                    <div v-if="cashierReceipt.SaleArrearBuyAmount" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount | NumFormat}}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleArrearBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.TotalAmount | NumFormat}}</span>
                    </div>

                    <div v-if="cashierReceipt.SaleArrearBalanceAmount" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.BalanceArrearAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.PackageCard">
                  <div :key="index+'PackageCard'+item.PackageCardID">
                    <!-- 套餐卡项目 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardProject" :key="Pindex + 'PProjectID' + packItem.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length
                          +saleOrderDetail.Product.length
                          }} {{packItem.Name}}
                        </span>
                      </div>

                      <div v-if="cashierReceipt.SaleArrearBuyAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleArrearBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleArrearBalanceAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.BalanceArrearAmount | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥ {{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡储值卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardSavingCard" :key="Pindex + 'PSavingCardID' + packItem.SavingCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length
                          +saleOrderDetail.Product.length}} {{packItem.Name}}
                          <span class="font_12" size="mini" v-if="packItem.IsLargess">(赠)</span>
                        </span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">充值金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(packItem.AccountTotalAmount || 0).toFixed(2) | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.Amount | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleArrearBalanceAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.BalanceArrearAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡时效卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardTimeCard" :key="Pindex + 'PTimeCardID' + packItem.TimeCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length
                          +saleOrderDetail.Product.length}} {{packItem.Name}}
                        </span>
                        <!-- <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span> -->
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.TotalAmount}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleArrearBalanceAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.BalanceArrearAmount | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡通用次卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardGeneralCard" :key="Pindex + 'PGeneralCardID' + packItem.GeneralCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length
                          +saleOrderDetail.Product.length}} {{packItem.Name}}
                          <span class="font_12" size="mini" v-if="packItem.IsLargess">(赠)</span>
                        </span>

                        <!-- <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span> -->
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.TotalAmount}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleArrearBalanceAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.BalanceArrearAmount | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>

                    <!-- 套餐卡产品 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardProduct" :key="Pindex + 'PProduct' + packItem.ProductID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +item.PackageCardGoods.PackageCardGeneralCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length
                          + saleOrderDetail.Product.length}} {{packItem.Name}}
                        </span>
                      </div>
                      <div v-if="cashierReceipt.SaleArrearBuyAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleArrearBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleArrearBalanceAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.BalanceArrearAmount | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">合计</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{saleOrderDetail.TotalAmount | NumFormat}}</span>
                </div>
                <div v-if="saleOrderDetail.SaleBillPay && saleOrderDetail.SaleBillPay.length > 0 " class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">付款：</span>
                  <div class="flex_box">
                    <div class="dis_flex" v-for="pay in saleOrderDetail.SaleBillPay" :key="pay.ID + 'pay'">
                      <span class="flex_box color_gray line_height_24">{{pay.Name}}</span>
                      <span class="flex_box text_right line_height_24">¥ {{pay.Amount | NumFormat}}</span>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div v-if="saleOrderDetail.SaleBillPaySavingCardDeduction && saleOrderDetail.SaleBillPaySavingCardDeduction.length > 0" class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">卡抵扣：</span>
                  <div class="flex_box">
                    <div class="dis_flex flex_box" v-for="cardPay in saleOrderDetail.SaleBillPaySavingCardDeduction" :key="cardPay.ID + 'cardPay'">
                      <span class="flex_box color_gray line_height_24">{{cardPay.Name}}</span>
                      <span class="flex_box text_right line_height_24">¥ {{cardPay.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </div>
                <div class="dis_flex" v-if="saleOrderDetail.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">手动改价</span>
                  <span class="flex_box font_12 text_right line_height_24" v-if="saleOrderDetail.PricePreferentialAmount>0">-￥{{saleOrderDetail.PricePreferentialAmount | NumFormat}}</span>
                  <span class="flex_box font_12 text_right line_height_24" v-else>+￥{{mathAbsData(saleOrderDetail.PricePreferentialAmount) | NumFormat}}</span>
                </div>
                <div class="dis_flex" v-if="saleOrderDetail.CardPreferentialAmount>0 && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">卡优惠</span>
                  <span class="flex_box font_12 text_right line_height_24">-￥{{saleOrderDetail.CardPreferentialAmount | NumFormat}}</span>
                </div>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex flex_dir_column font_14 font_weight_600 flex_y_center color_999 padbm_10">
                  <span>{{cashierReceipt.WriteTextFirst}}</span>
                  <span>{{cashierReceipt.WriteTextSecond}}</span>
                </div>

                <div class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">签字：</span>
                </div>
              </div>
            </el-scrollbar>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="arrearCashierReceiptDialogVisible = false;" size="small" :disabled="modalLoading">取 消</el-button>
        <el-button type="primary" @click="printArrearSaleBillContent" :loading="modalLoading" v-prevent-click size="small">打印</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import API from "@/api/iBeauty/Order/saleBill";
import permission from "@/components/js/permission.js";
import saleAPI from "@/api/iBeauty/Order/saleGoods";
import printReceipt from "@/components/js/print";
import cashierAPI from "@/api/iBeauty/Order/cashierReceipt";
import SaleBillDetailContent from "@/views/iBeauty/Components/saleBillDetailContent";
import ArrearDetailContent from "@/views/iBeauty/Components/arrearDetailContent";
import RefundDetailContent from "@/views/iBeauty/Components/refundDetailContent";
import saleCashierReceipt from "@/views/iBeauty/Components/CashierReceipt/saleCashierReceipt";
import receiptInfo from "@/views/iBeauty/Components/CashierReceipt/cashierReceiptInfo.vue";
// import receiptParticle from "@/views/iBeauty/Components/CashierReceipt/receiptParticle.vue"

// saleOrderDetail

var Enumerable = require("linq");
var socket;

export default {
  name: "SaleBill",
  components: {
    SaleBillDetailContent,
    ArrearDetailContent,
    RefundDetailContent,
    saleCashierReceipt,
    receiptInfo,
    // receiptParticle,
  },

  data() {
    return {
      showFileList: [],
      isDelete: true,
      fileList: [],
      saleActiveName: "0",
      arrearActiveName: "0",
      refundActiveName: "0",
      tableData: [],
      loading: false,
      cashierReceiptDialogVisible: false,
      refundCashierReceiptDialogVisible: false,
      arrearCashierReceiptDialogVisible: false,
      handlerName: "",
      tabHandle: "0",
      Remark: "",
      customerName: "",
      customerID: null,
      searchData: {
        createTime: "",
        EndDate: "",
        StartDate: "",
        BillType: "",
        Name: "",
        SaleBillID: "",
        BillStatus: "",
        ReturnedCommissionType:''
      },
      saleOrderList: [], //订单列表
      saleOrderDetail: {},
      backMoneyTable: [],
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      dialogVisible: false, //销售单详情
      arreardialogVisible: false, //补欠款详情
      refundDialogVisible: false, //退单详情

      innerVisible: false, //备注弹窗
      backMoneyVisible: false, //还款记录弹窗
      backMoney: false, //退款弹窗
      changePerformance: false, //修改员工业绩弹窗
      arrearChangePerformance: false, // 修改补欠款 修改业绩弹窗
      refundChangePerformance: false, // 修改补欠款 修改业绩弹窗
      modalLoading: false,

      // changeMemberDialogVisible: false,

      beauticianVisible: false, //美容师经手人弹窗
      PerformanceInfo: null, //获取员工业绩和佣金信息
      ProjectEmployee: [],
      projectHandlerList: [], //项目经手人列表
      savingCardHandlerList: [], //储蓄卡经手人列表
      timeCardHandlerList: [],
      generalCardHandlerList: [],
      packageCardHandlerList: [],
      productHandlerList: [],
      handlerList: [], //公共经手人列表
      type: 1,
      SaleHandler: [],
      IsPackageCard: "",
      /** 修改是使用的属性字段  */
      currentItem: {}, //当前修改业绩的品项
      currentItemId: "", //计算佣金时使用
      currentItemType: 0, //计算佣金时使用 商品类型，1：产品 2：项目 3：次卡 4：时效卡 5：储值卡
      isCancel: false, //是否允许取消订单
      isModifyPerformanceCommission: false, //是否允许修改业绩提成
      modifyPerformanceCommissionLoading: false, //修改经手人业绩提成按钮Loading
      cancelSaleBillLoading: false, //取消销售订单按钮Loading
      savePerformanceCommissionLoading: false, //保存经手人业绩提成修改按钮Loading
      BillType: 0, // 订单类型
      SaleBillGoodsID: "", //
      userInfo: null, //登录账户信息
      cashierReceipt: "", // 小票配置信息
      Signature:{
        CreatedOn:'',
        ImageURL:''
      },
      
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isCancel = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-SaleBill-Cancel"
      );
      vm.isModifyPerformanceCommission = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-SaleBill-ModifyPerformanceCommission"
      );
      // 附件删除权限
      vm.isDelete = permission.permission(
        to.meta.Permission,
        "iBeauty-Order-SaleBill-DeleteAttachment"
      );
    });
  },
  methods: {
    // 查看大图
    DialogPreview(file) {
      document.getElementById(file.name).click();
    },
    unset() {},
    // 上传附件
    updataimg(file) {
      let that = this;
      if (
        file.type !== "image/jpg" &&
        file.type !== "image/png" &&
        file.type !== "image/jpeg"
      ) {
        this.$message.error("仅支持上传jpg,png,jpeg格式!");
        return false;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (evt) {
        var base64 = evt.target.result;
        var parme = {
          SaleBillID: that.SaleBillID,
          imgAttachment: {
            AttachmentURL: base64,
            AttachmentType: "10",
          },
        };
        API.addSaleBillAttachment(parme).then((res) => {
          if (res.StateCode == 200) {
            that.fileList.push({
              name: res.Data.ID,
              url: res.Data.AttachmentURL,
            });
            that.showFileList.push(res.Data.AttachmentURL);
          }
        });
      };
      return false;
    },
    // 获取附件
    getImageList() {
      var parme = { SaleBillID: this.SaleBillID };
      var list = [];
      API.getSaleBillAttachment(parme).then((res) => {
        if (res.StateCode == 200) {
          this.fileList = res.Data.map((item) => {
            return {
              url: item.AttachmentURL,
              name: item.ID,
              status: "success",
            };
          });
          this.showFileList = res.Data.map((item) => {
            return item.AttachmentURL;
          });
        }
      });
    },
    // 删除附件
    deleteImg(file, fileList) {
      this.$confirm("此操作将删除当前附件, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var parme = {
            ID: file.name,
            AttachmentType: "10",
          };
          API.deleteSaleBillAttachment(parme).then((res) => {
            if (res.StateCode == 200) {
              let deleIndex = this.fileList.findIndex((item) => {
                return file.name == item.name;
              });
              this.fileList.splice(deleIndex, 1);
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
    // 获取签名
    getSignature(){
      API.getSignature({ID:this.SaleBillID}).then(res=>{
        console.log(res);
        if(res.StateCode==200){
          if(res.Data){
            this.Signature=res.Data
          }else{
            this.Signature={
                  CreatedOn:'',
                  ImageURL:''
                }
          }
          
        }else{
          this.$message.error({
            message:res.Message,
            duration:2000
          })
        }
      })
    },
    handleSearch: function () {
      let that = this;
      that.paginations.page = 1;
      that.searchSaleBill();
    },
    //搜索
    searchSaleBill() {
      var that = this;
      that.loading = true;
      if (
        that.searchData.createTime == null ||
        that.searchData.createTime == ""
      ) {
        that.StartDate = "";
        that.EndDate = "";
      } else {
        that.StartDate = that.searchData.createTime[0];
        that.EndDate = that.searchData.createTime[1];
      }

      var params = {
        PageNum: that.paginations.page,
        Name: that.searchData.Name,
        SaleBillID: that.searchData.SaleBillID,
        StartDate: that.StartDate,
        EndDate: that.EndDate,
        BillStatus: that.searchData.BillStatus,
        BillType: that.searchData.BillType,
        ReturnedCommissionType:that.searchData.ReturnedCommissionType
      };
      API.getOrderList(params)
        .then((res) => {
          console.log(res)
          if (res.StateCode == 200) {
            that.saleOrderList = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //翻页
    handleCurrentChange(page) {
      this.paginations.page = page;
      this.searchSaleBill();
    },
    //查看详情
    getOrderDetail(row) {
      var that = this;
      that.loading = true;
      that.SaleBillID = row.ID;
      that.BillType = row.BillType;

      var params = {
        SaleBillID: row.ID,
      };

      API.getOrderDetail(params)
        .then((res) => {
          console.log(res)
          if (res.StateCode == 200) {
            that.saleOrderDetail = res.Data;
            that.backMoneyTable = res.Data.RepayBill;
            that.Remark = that.saleOrderDetail.Remark;
            console.log(that.Remark)
            this.getImageList();
            // 获取签名
            this.getSignature()
            if (that.saleOrderDetail.BillType == 10) {
              // 销售订单详情
              this.saleActiveName = "0";
              that.dialogVisible = true;
            } else if (that.saleOrderDetail.BillType == 20) {
              // 销售退单详情
              this.refundActiveName = "0";
              that.refundDialogVisible = true;
            } else if (that.saleOrderDetail.BillType == 30) {
              // 销售补欠款订单详情
              this.arrearActiveName = "0";
              that.arreardialogVisible = true;
            }
          }
        })
        .finally(function () {
          that.loading = false;
        });

      that.getReceiptConfig(row.ID);
    },

    //取消订单
    CancelOrder() {
      var that = this;
      that.cancelSaleBillLoading = true;
      this.$prompt("请输入取消原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputValidator: (value) => {
          if (!value) {
            return "输入不能为空";
          }
        },
      })
        .then(({ value }) => {
          var params = {
            SaleBillID: that.SaleBillID,
            Remark: value,
          };

          API.cancelOrder(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success({
                  message: "取消成功",
                  duration: 2000,
                });
                that.searchSaleBill();
                that.cancelOrder = false;

                if (that.saleOrderDetail.BillType == 10) {
                  // 销售订单详情
                  that.dialogVisible = false;
                } else if (that.saleOrderDetail.BillType == 20) {
                  // 销售退单详情
                  that.refundDialogVisible = false;
                } else if (that.saleOrderDetail.BillType == 30) {
                  // 销售补欠款订单详情
                  that.arreardialogVisible = false;
                }
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.cancelSaleBillLoading = false;
            });
        })
        .catch(() => {
          that.cancelSaleBillLoading = false;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });

      // this.$confirm("此操作将取消当前订单, 是否继续?", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      //   showInput:true,
      //    inputType:"textarea",
      //    inputPlaceholder:"请输入取消原因",
      //    inputValidator:aa,
      //    inputErrorMessage:"取消原因不能为空，六位以上"
      // })
      //   .then(({value}) => {
      //     if(!value){
      //       that.$message.error({
      //         message:"请输入取消原因",
      //         duration:2000
      //       })
      //       return false
      //     }
      //     var params = {
      //       SaleBillID: that.SaleBillID,
      //     };
      //     API.cancelOrder(params)
      //       .then((res) => {
      //         if (res.StateCode == 200) {
      //           that.$message.success({
      //             message: "销售单取消成功",
      //             duration: 2000,
      //           });
      //           that.searchSaleBill();
      //           that.cancelOrder = false;

      //           if (that.saleOrderDetail.BillType == 10) {
      //             // 销售订单详情
      //             that.dialogVisible = false;
      //           } else if (that.saleOrderDetail.BillType == 20) {
      //             // 销售退单详情
      //             that.refundDialogVisible = false;
      //           } else if (that.saleOrderDetail.BillType == 30) {
      //             // 销售补欠款订单详情
      //             that.arreardialogVisible = false;
      //           }
      //         } else {
      //           that.$message.error({
      //             message: res.Message,
      //             duration: 2000,
      //           });
      //         }
      //       })
      //       .finally(function () {
      //         that.cancelSaleBillLoading = false;
      //       });
      //   })
      //   .catch(() => {
      //     that.cancelSaleBillLoading = false;
      //     this.$message({
      //       type: "info",
      //       message: "已取消操作",
      //     });
      //   });
    },
    //修改业绩提成
    changePerformancecommission() {
      var that = this;
      that.modifyPerformanceCommissionLoading = true;
      var params = {
        SaleBillID: that.SaleBillID,
      };
      API.getOrderPerformanceCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.AllCardList = res.Data;
            that.PerformanceInfo = res.Data;
            /**  项目  */
            that.PerformanceInfo.Project.forEach((item) => {
              if (item.SaleBillHandler.length == 0) {
                that.projectHandlerList.forEach((hand) => {
                  item.SaleBillHandler.push({
                    SaleHandlerID: hand.ID,
                    SaleHandlerName: hand.Name,
                    Employee: [],
                  });
                });
              }
            });
            /**  产品  */
            that.PerformanceInfo.Product.forEach((item) => {
              if (item.SaleBillHandler.length == 0) {
                that.productHandlerList.forEach((hand) => {
                  item.SaleBillHandler.push({
                    SaleHandlerID: hand.ID,
                    SaleHandlerName: hand.Name,
                    Employee: [],
                  });
                });
              }
            });
            /**  储值卡  */
            that.PerformanceInfo.SavingCard.forEach((item) => {
              if (item.SaleBillHandler.length == 0) {
                that.savingCardHandlerList.forEach((hand) => {
                  item.SaleBillHandler.push({
                    SaleHandlerID: hand.ID,
                    SaleHandlerName: hand.Name,
                    Employee: [],
                  });
                });
              }
            });
            /**  时效卡  */
            that.PerformanceInfo.TimeCard.forEach((item) => {
              if (item.SaleBillHandler.length == 0) {
                that.timeCardHandlerList.forEach((hand) => {
                  item.SaleBillHandler.push({
                    SaleHandlerID: hand.ID,
                    SaleHandlerName: hand.Name,
                    Employee: [],
                  });
                });
              }
            });
            /**  通用次卡  */
            that.PerformanceInfo.GeneralCard.forEach((item) => {
              if (item.SaleBillHandler.length == 0) {
                that.generalCardHandlerList.forEach((hand) => {
                  item.SaleBillHandler.push({
                    SaleHandlerID: hand.ID,
                    SaleHandlerName: hand.Name,
                    Employee: [],
                  });
                });
              }
            });
            /**  套餐卡  */
            that.PerformanceInfo.PackageCard.forEach((item) => {
              if (item.SaleBillHandler.length == 0) {
                that.packageCardHandlerList.forEach((hand) => {
                  item.SaleBillHandler.push({
                    SaleHandlerID: hand.ID,
                    SaleHandlerName: hand.Name,
                    Employee: [],
                  });
                });
              }
            });
          }

          if (that.saleOrderDetail.BillType == 10) {
            // 销售订单详情
            that.changePerformance = true;
          } else if (that.saleOrderDetail.BillType == 20) {
            // 销售退单详情
            that.refundChangePerformance = true;
          } else if (that.saleOrderDetail.BillType == 30) {
            // 销售补欠款订单详情
            that.arrearChangePerformance = true;
          }
        })
        .finally(function () {
          that.modifyPerformanceCommissionLoading = false;
        });
    },
    //获取项目经手人列表
    getProjectSaleHandlerList() {
      var that = this;
      API.getProjectSaleHandlerList().then((res) => {
        if (res.StateCode == 200) {
          that.projectHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //获取产品经手人列表
    getProductSaleHandlerList() {
      var that = this;
      API.getproductSaleHandlerList().then((res) => {
        if (res.StateCode == 200) {
          that.productHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取储值卡经手人列表
    getSaveCardSaleHandlerList() {
      var that = this;
      API.getSaveSaleHandlerList().then((res) => {
        if (res.StateCode == 200) {
          that.savingCardHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //获取通用次卡经手人列表
    getGenerSaleHandlerList() {
      var that = this;
      API.getGenerSaleHandlerList().then((res) => {
        if (res.StateCode == 200) {
          that.generalCardHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //获取时效卡经手人列表
    getTimeSaleHandlerList() {
      var that = this;
      API.getTimeSaleHandlerList().then((res) => {
        if (res.StateCode == 200) {
          that.timeCardHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //获取套餐卡经手人列表
    getPackageSaleHandlerList() {
      var that = this;
      API.getPackageSaleHandlerList().then((res) => {
        if (res.StateCode == 200) {
          that.packageCardHandlerList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    /**  查看套餐卡明细  */
    packDetailClick(item) {
      item.IsShowDetails = !item.IsShowDetails;
    },
    //点击判断经手人
    employeeHandleClick(type, item) {
      var that = this;
      that.tabHandle = "0";
      var Employee = [];
      switch (type) {
        case 1:
          that.handlerList = that.projectHandlerList;
          that.IsPackageCard = false;
          that.currentItemId = item.ProjectID;
          that.SaleBillGoodsID = item.SaleBillProjectID;
          that.currentItemType = 2;
          break;
        case 2:
          that.handlerList = that.productHandlerList;
          that.IsPackageCard = false;
          that.currentItemId = item.ProductID;
          that.SaleBillGoodsID = item.SaleBillProductID;
          that.currentItemType = 1;
          break;
        case 3:
          that.handlerList = that.generalCardHandlerList;
          that.IsPackageCard = false;
          that.currentItemId = item.GeneralCardID;
          that.SaleBillGoodsID = item.SaleBillGeneralCardID;
          that.currentItemType = 3;
          break;
        case 4:
          that.handlerList = that.timeCardHandlerList;
          that.IsPackageCard = false;
          that.currentItemId = item.TimeCardID;
          that.SaleBillGoodsID = item.SaleBillTimeCardID;
          that.currentItemType = 4;
          break;
        case 5:
          that.handlerList = that.savingCardHandlerList;
          that.IsPackageCard = false;
          that.currentItemId = item.SavingCardID;
          that.SaleBillGoodsID = item.SaleBillSavingCardID;
          that.currentItemType = 5;
          break;
        case 6:
          that.handlerList = that.packageCardHandlerList;
          that.currentItemId = item.PackageCardID;
          that.SaleBillGoodsID = item.SaleBillPackageID;
          that.IsPackageCard = true;
          that.currentItemType = 6;
          break;
      }
      item.SaleBillHandler.forEach(function (hand) {
        hand.Employee.forEach(function (emp) {
          Employee.push({
            ID: hand.SaleHandlerID + "-" + emp.EmployeeID,
            Discount: emp.Scale,
          });
        });
      });
      that.handlerList.forEach(function (handler) {
        handler.Employee.forEach(function (emp) {
          emp.Checked = false;
          emp.Discount = "";
          Employee.forEach(function (i) {
            if (emp.ID == i.ID) {
              emp.Checked = true;
              emp.Discount = i.Discount;
            }
          });
        });
      });

      that.type = type;
      that.currentItem = item;
      that.beauticianVisible = true;
    },
    //经手人选择
    handlerCheckedChange: function (row, item) {
      var that = this;
      var discount = 0;
      var SaleHandlers = [];
      var employee = Enumerable.from(row)
        .where(function (i) {
          return i.Checked;
        })
        .toArray();
      employee.forEach(function (emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
      if (!item.Checked) {
        item.Discount = "";
      } else {
        if (item.Discount == "") {
          if (discount > 100) {
            item.Discount = 0;
          } else {
            item.Discount = 100 - discount;
          }
        }
      }
      that.handlerList.forEach((empList) => {
        empList.Employee.forEach((hand) => {
          if (hand.Checked == true) {
            SaleHandlers.push({
              SaleHandlerID: hand.SaleHandlerID,
              EmployeeID: hand.EmployeeID,
              Scale: hand.Discount,
            });
          }
        });
      });

      that.SaleHandler = SaleHandlers;
    },
    //经手人确认
    submitHandleClick: function () {
      var that = this;
      var SaleHandlers = [];
      that.handlerList.forEach(function (handler) {
        handler.Employee.forEach(function (emp) {
          if (emp.Checked == true) {
            SaleHandlers.push({
              SaleHandlerID: emp.SaleHandlerID,
              EmployeeID: emp.EmployeeID,
              Scale: emp.Discount,
            });
          }
        });
      });

      that.SaleHandler = SaleHandlers;
      if (that.BillType == 30) {
        //30：补尾款
        that.getsaleBillArrearEmployeeCommission();
      } else if (that.BillType == 20) {
        //20：退款
        that.getsaleBillRefundemployeeCommission();
      } else {
        that.getsaleBillemployeeCommission();
      }
    },
    // 修改备注
    updateRemarkClick() {
      var that = this;
      that.innerVisible = false;
      that.updateRemark();
    },
    //获取 销售 计算佣金
    getsaleBillemployeeCommission() {
      var that = this;
      if (that.currentItemType != 6) {
        //非套餐卡
        if (that.currentItem.PayAmount == null) {
          that.currentItem.PayAmount = 0;
        }
        if (that.currentItem.CardDeductionAmount == null) {
          that.currentItem.CardDeductionAmount = 0;
        }
        if (that.currentItem.CardDeductionLargessAmount == null) {
          that.currentItem.CardDeductionLargessAmount = 0;
        }
        var params = {
          GoodsID: that.currentItemId,
          Quantity: that.currentItem.Quantity,
          IsLargess: that.currentItem.IsLargess,
          Type: that.currentItemType,
          TotalAmount: that.currentItem.TotalAmount,
          PayAmount: that.currentItem.PayAmount,
          CardDeductionAmount: that.currentItem.CardDeductionAmount,
          CardDeductionLargessAmount:
            that.currentItem.CardDeductionLargessAmount,
          IsPackageCard: that.IsPackageCard,
          SaleHandler: that.SaleHandler,
        };
        API.saleBillemployeeCommission(params).then((res) => {
          if (res.StateCode == 200) {
            that.currentItem.SaleBillHandler = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        });
      } else {
        //套餐卡
        //套餐卡产品
        that.currentItem.Product.forEach((childItem) => {
          var paramsProduct = {
            GoodsID: childItem.ProductID,
            Quantity: childItem.Quantity * that.currentItem.Quantity,
            IsLargess: that.currentItem.IsLargess || childItem.IsLargess,
            Type: 1,
            TotalAmount: childItem.TotalAmount,
            PayAmount: childItem.PayAmount == null ? 0 : childItem.PayAmount,
            CardDeductionAmount:
              childItem.CardDeductionAmount == null
                ? 0
                : childItem.CardDeductionAmount,
            CardDeductionLargessAmount:
              childItem.CardDeductionLargessAmount == null
                ? 0
                : childItem.CardDeductionLargessAmount,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillemployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
        //套餐卡项目
        that.currentItem.Project.forEach((childItem) => {
          var paramsProduct = {
            GoodsID: childItem.ProjectID,
            Quantity: childItem.Quantity * that.currentItem.Quantity,
            IsLargess: that.currentItem.IsLargess || childItem.IsLargess,
            Type: 2,
            TotalAmount: childItem.TotalAmount,
            PayAmount: childItem.PayAmount == null ? 0 : childItem.PayAmount,
            CardDeductionAmount:
              childItem.CardDeductionAmount == null
                ? 0
                : childItem.CardDeductionAmount,
            CardDeductionLargessAmount:
              childItem.CardDeductionLargessAmount == null
                ? 0
                : childItem.CardDeductionLargessAmount,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillemployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
        //通用次卡
        that.currentItem.GeneralCard.forEach((childItem) => {
          var paramsProduct = {
            GoodsID: childItem.GeneralCardID,
            Quantity: childItem.Quantity * that.currentItem.Quantity,
            IsLargess: that.currentItem.IsLargess || childItem.IsLargess,
            Type: 3,
            TotalAmount: childItem.TotalAmount,
            PayAmount: childItem.PayAmount == null ? 0 : childItem.PayAmount,
            CardDeductionAmount:
              childItem.CardDeductionAmount == null
                ? 0
                : childItem.CardDeductionAmount,
            CardDeductionLargessAmount:
              childItem.CardDeductionLargessAmount == null
                ? 0
                : childItem.CardDeductionLargessAmount,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillemployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
        //时效卡
        that.currentItem.TimeCard.forEach((childItem) => {
          var paramsProduct = {
            GoodsID: childItem.TimeCardID,
            Quantity: childItem.Quantity * that.currentItem.Quantity,
            IsLargess: that.currentItem.IsLargess || childItem.IsLargess,
            Type: 4,
            TotalAmount: childItem.TotalAmount,
            PayAmount: childItem.PayAmount == null ? 0 : childItem.PayAmount,
            CardDeductionAmount:
              childItem.CardDeductionAmount == null
                ? 0
                : childItem.CardDeductionAmount,
            CardDeductionLargessAmount:
              childItem.CardDeductionLargessAmount == null
                ? 0
                : childItem.CardDeductionLargessAmount,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillemployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
        //储值卡
        that.currentItem.SavingCard.forEach((childItem) => {
          var paramsProduct = {
            GoodsID: childItem.SavingCardID,
            Quantity: childItem.Quantity * that.currentItem.Quantity,
            IsLargess: that.currentItem.IsLargess || childItem.IsLargess,
            Type: 5,
            TotalAmount: childItem.TotalAmount,
            PayAmount: childItem.PayAmount == null ? 0 : childItem.PayAmount,
            CardDeductionAmount: 0,
            CardDeductionLargessAmount: 0,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillemployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
      }
      that.beauticianVisible = false;
    },
    //获取 销售 欠尾 计算佣金
    getsaleBillArrearEmployeeCommission() {
      var that = this;
      if (that.currentItemType != 6) {
        //非套餐卡

        var params = {
          SaleBillGoodsID: that.SaleBillGoodsID,
          GoodsID: that.currentItemId,
          Type: that.currentItemType,
          IsPackageCard: that.IsPackageCard,
          SaleHandler: that.SaleHandler,
        };
        API.saleBillArrearEmployeeCommission(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.modalLoading = false;
          });
      } else {
        //套餐卡
        //套餐卡产品
        that.currentItem.Product.forEach((childItem) => {
          var paramsProduct = {
            SaleBillGoodsID: childItem.SaleBillPackageCardProductID,
            GoodsID: childItem.ProductID,
            Type: 1,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillArrearEmployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
        //套餐卡项目
        that.currentItem.Project.forEach((childItem) => {
          var paramsProduct = {
            SaleBillGoodsID: childItem.SaleBillPackageCardProjectID,
            GoodsID: childItem.ProjectID,
            Type: 2,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillArrearEmployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
        //通用次卡
        that.currentItem.GeneralCard.forEach((childItem) => {
          var paramsProduct = {
            SaleBillGoodsID: childItem.SaleBillPackageCardGeneralCardID,
            GoodsID: childItem.GeneralCardID,
            Type: 3,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillArrearEmployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
        //时效卡
        that.currentItem.TimeCard.forEach((childItem) => {
          var paramsProduct = {
            SaleBillGoodsID: childItem.SaleBillPackageCardTimeCardID,
            GoodsID: childItem.TimeCardID,
            Type: 4,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillArrearEmployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
        //储值卡
        that.currentItem.SavingCard.forEach((childItem) => {
          var paramsProduct = {
            SaleBillGoodsID: childItem.SaleBillPackageCardSavingCardID,
            GoodsID: childItem.SavingCardID,
            Type: 5,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillArrearEmployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
      }
      that.beauticianVisible = false;
    },
    //获取 销售退款 计算佣金
    getsaleBillRefundemployeeCommission() {
      var that = this;
      if (that.currentItemType != 6) {
        //非套餐卡
        if (that.currentItem.PayAmount == null) {
          that.currentItem.PayAmount = 0;
        }
        if (that.currentItem.CardDeductionAmount == null) {
          that.currentItem.CardDeductionAmount = 0;
        }
        if (that.currentItem.CardDeductionLargessAmount == null) {
          that.currentItem.CardDeductionLargessAmount = 0;
        }
        var params = {
          GoodsID: that.currentItemId,
          Quantity:
            that.currentItem.AccountQuantity == undefined
              ? that.currentItem.Quantity
              : that.currentItem.AccountQuantity,
          IsLargess: that.currentItem.IsLargess,
          Type: that.currentItemType,
          TotalAmount: that.currentItem.AccountTotalAmount,
          PayAmount: that.currentItem.PayAmount,
          CardDeductionAmount: that.currentItem.CardDeductionAmount,
          CardDeductionLargessAmount:
            that.currentItem.CardDeductionLargessAmount,
          IsPackageCard: that.IsPackageCard,
          SaleHandler: that.SaleHandler,
        };
        API.saleBillemployeeCommission(params).then((res) => {
          if (res.StateCode == 200) {
            that.currentItem.SaleBillHandler = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        });
      } else {
        //套餐卡
        //套餐卡产品
        that.currentItem.Product.forEach((childItem) => {
          var paramsProduct = {
            GoodsID: childItem.ProductID,
            Quantity: childItem.Quantity * that.currentItem.Quantity,
            IsLargess: that.currentItem.IsLargess || childItem.IsLargess,
            Type: 1,
            TotalAmount: childItem.TotalAmount,
            PayAmount: childItem.PayAmount == null ? 0 : childItem.PayAmount,
            CardDeductionAmount:
              childItem.CardDeductionAmount == null
                ? 0
                : childItem.CardDeductionAmount,
            CardDeductionLargessAmount:
              childItem.CardDeductionLargessAmount == null
                ? 0
                : childItem.CardDeductionLargessAmount,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillemployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
        //套餐卡项目
        that.currentItem.Project.forEach((childItem) => {
          var paramsProduct = {
            GoodsID: childItem.ProjectID,
            Quantity: childItem.Quantity * that.currentItem.Quantity,
            IsLargess: that.currentItem.IsLargess || childItem.IsLargess,
            Type: 2,
            TotalAmount: childItem.TotalAmount,
            PayAmount: childItem.PayAmount == null ? 0 : childItem.PayAmount,
            CardDeductionAmount:
              childItem.CardDeductionAmount == null
                ? 0
                : childItem.CardDeductionAmount,
            CardDeductionLargessAmount:
              childItem.CardDeductionLargessAmount == null
                ? 0
                : childItem.CardDeductionLargessAmount,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillemployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
        //通用次卡
        that.currentItem.GeneralCard.forEach((childItem) => {
          var paramsProduct = {
            GoodsID: childItem.GeneralCardID,
            Quantity: childItem.Quantity * that.currentItem.Quantity,
            IsLargess: that.currentItem.IsLargess || childItem.IsLargess,
            Type: 3,
            TotalAmount: childItem.TotalAmount,
            PayAmount: childItem.PayAmount == null ? 0 : childItem.PayAmount,
            CardDeductionAmount:
              childItem.CardDeductionAmount == null
                ? 0
                : childItem.CardDeductionAmount,
            CardDeductionLargessAmount:
              childItem.CardDeductionLargessAmount == null
                ? 0
                : childItem.CardDeductionLargessAmount,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillemployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
        //时效卡
        that.currentItem.TimeCard.forEach((childItem) => {
          var paramsProduct = {
            GoodsID: childItem.TimeCardID,
            Quantity: childItem.Quantity * that.currentItem.Quantity,
            IsLargess: that.currentItem.IsLargess || childItem.IsLargess,
            Type: 4,
            TotalAmount: childItem.TotalAmount,
            PayAmount: childItem.PayAmount == null ? 0 : childItem.PayAmount,
            CardDeductionAmount:
              childItem.CardDeductionAmount == null
                ? 0
                : childItem.CardDeductionAmount,
            CardDeductionLargessAmount:
              childItem.CardDeductionLargessAmount == null
                ? 0
                : childItem.CardDeductionLargessAmount,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillemployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
        //储值卡
        that.currentItem.SavingCard.forEach((childItem) => {
          var paramsProduct = {
            GoodsID: childItem.SavingCardID,
            Quantity: childItem.Quantity * that.currentItem.Quantity,
            IsLargess: that.currentItem.IsLargess || childItem.IsLargess,
            Type: 5,
            TotalAmount: childItem.TotalAmount,
            PayAmount: childItem.PayAmount == null ? 0 : childItem.PayAmount,
            CardDeductionAmount: 0,
            CardDeductionLargessAmount: 0,
            IsPackageCard: true,
            SaleHandler: that.SaleHandler,
          };
          API.saleBillemployeeCommission(paramsProduct).then((res) => {
            if (res.StateCode == 200) {
              childItem.SaleBillHandler = res.Data;
              that.currentItem.SaleBillHandler = res.Data;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        });
      }
      that.beauticianVisible = false;
    },
    //修改经手人比例
    changeSaleHandlerRate(item, employee, itemType) {
      //itemType（10：项目、20：储值卡、30：时效卡、40：通用次卡、50：产品）
      var that = this;
      if (that.BillType == 20) {
        //20：退款、
        //现金
        if (item.PayAmount > 0) {
          if (employee.PayRate == null && employee.PayFixed == null) {
            employee.PayPerformance = 0;
            employee.PayRateCommission = 0;
            employee.PayFixedCommission = 0;
          } else {
            employee.PayPerformance = (
              (item.PayAmount * employee.Scale) /
              100
            ).toFixed(2);
            if (employee.PayRate != null) {
              employee.PayRateCommission = (
                (employee.PayPerformance * employee.PayRate) /
                100
              ).toFixed(2);
            } else {
              employee.PayRateCommission = 0;
            }
            if (employee.PayFixed != null) {
              employee.PayFixedCommission = (
                (employee.PayPerformance / item.AccountTotalAmount) *
                employee.PayFixed *
                (item.AccountQuantity == undefined
                  ? item.Quantity
                  : item.AccountQuantity)
              ).toFixed(2);
            } else {
              employee.PayFixedCommission = 0;
            }
          }
        } else {
          employee.PayPerformance = 0;
          employee.PayRateCommission = 0;
          employee.PayFixedCommission = 0;
        }

        //卡抵扣
        if (itemType != 20) {
          //储值没有卡抵扣
          if (item.CardDeductionAmount > 0) {
            if (
              employee.SavingCardRate == null &&
              employee.SavingCardFixed == null
            ) {
              employee.SavingCardPerformance = 0;
              employee.SavingCardRateCommission = 0;
              employee.SavingCardFixedCommission = 0;
            } else {
              employee.SavingCardPerformance = (
                (item.CardDeductionAmount * employee.Scale) /
                100
              ).toFixed(2);
              if (employee.SavingCardRate != null) {
                employee.SavingCardRateCommission = (
                  (employee.SavingCardPerformance * employee.SavingCardRate) /
                  100
                ).toFixed(2);
              } else {
                employee.SavingCardRateCommission = 0;
              }
              if (employee.SavingCardFixed != null) {
                employee.SavingCardFixedCommission = (
                  (employee.SavingCardPerformance / item.AccountTotalAmount) *
                  employee.SavingCardFixed *
                  (item.AccountQuantity == undefined
                    ? item.Quantity
                    : item.AccountQuantity)
                ).toFixed(2);
              }
            }
          } else {
            employee.SavingCardPerformance = 0;
            employee.SavingCardRateCommission = 0;
            employee.SavingCardFixedCommission = 0;
          }
        }

        //赠送卡抵扣
        if (itemType != 20) {
          //储值没有卡抵扣
          if (item.CardDeductionLargessAmount > 0) {
            if (
              employee.SavingCardLargessRate == null &&
              employee.SavingCardLargessFixed == null
            ) {
              employee.SavingCardLargessPerformance = 0;
              employee.SavingCardLargessRateCommission = 0;
              employee.SavingCardLargessFixedCommission = 0;
            } else {
              employee.SavingCardLargessPerformance = (
                (item.CardDeductionLargessAmount * employee.Scale) /
                100
              ).toFixed(2);
              if (employee.SavingCardLargessRate != null) {
                employee.SavingCardLargessRateCommission = (
                  (employee.SavingCardLargessPerformance *
                    employee.SavingCardLargessRate) /
                  100
                ).toFixed(2);
              } else {
                employee.SavingCardLargessRateCommission = 0;
              }
              if (employee.SavingCardLargessFixed != null) {
                employee.SavingCardLargessFixedCommission = (
                  (employee.SavingCardLargessPerformance /
                    item.AccountTotalAmount) *
                  employee.SavingCardLargessFixed *
                  (item.AccountQuantity == undefined
                    ? item.Quantity
                    : item.AccountQuantity)
                ).toFixed(2);
              } else {
                employee.SavingCardLargessFixedCommission = 0;
              }
            }
          } else {
            employee.SavingCardLargessPerformance = 0;
            employee.SavingCardLargessRateCommission = 0;
            employee.SavingCardLargessFixedCommission = 0;
          }
        }

        //特殊补贴
        if (employee.SpecialBenefit != null) {
          if (itemType != 20) {
            //储值没有卡抵扣
            employee.SpecialBenefitCommission = (
              ((parseFloat(item.PayAmount) +
                parseFloat(item.CardDeductionTotalAmount)) /
                item.AccountTotalAmount) *
              employee.SpecialBenefit *
              (item.AccountQuantity == undefined
                ? item.Quantity
                : item.AccountQuantity)
            ).toFixed(2);
          } else {
            // employee.SpecialBenefitCommission = (item.PayAmount / item.TotalAmount * employee.SpecialBenefit * item.Quantity).toFixed(2);
            employee.SpecialBenefitCommission = (
              (item.TotalAmount / item.AccountTotalAmount) *
              employee.SpecialBenefit *
              (item.AccountQuantity == undefined
                ? item.Quantity
                : item.AccountQuantity)
            ).toFixed(2);
          }
        } else {
          employee.SpecialBenefitCommission = 0;
        }
      } else if (that.BillType == 30) {
        //30：不欠款
        //现金
        if (item.PayAmount > 0) {
          if (employee.PayRate == null && employee.PayFixed == null) {
            employee.PayPerformance = 0;
            employee.PayRateCommission = 0;
            employee.PayFixedCommission = 0;
          } else {
            employee.PayPerformance = (
              (item.PayAmount * employee.Scale) /
              100
            ).toFixed(2);
            if (employee.PayRate != null) {
              employee.PayRateCommission = (
                (employee.PayPerformance * employee.PayRate) /
                100
              ).toFixed(2);
            } else {
              employee.PayRateCommission = 0;
            }
            if (employee.PayFixed != null) {
              // employee.PayFixedCommission = (employee.PayPerformance/item.TotalAmount*employee.PayFixed*item.Quantity).toFixed(2)
              employee.PayFixedCommission = (
                (employee.PayPerformance / item.AccountTotalAmount) *
                employee.PayFixed *
                item.Quantity
              ).toFixed(2);
            } else {
              employee.PayFixedCommission = 0;
            }
          }
        } else {
          employee.PayPerformance = 0;
          employee.PayRateCommission = 0;
          employee.PayFixedCommission = 0;
        }

        //卡抵扣
        if (itemType != 20) {
          //储值没有卡抵扣
          if (item.CardDeductionAmount > 0) {
            if (
              employee.SavingCardRate == null &&
              employee.SavingCardFixed == null
            ) {
              employee.SavingCardPerformance = 0;
              employee.SavingCardRateCommission = 0;
              employee.SavingCardFixedCommission = 0;
            } else {
              employee.SavingCardPerformance = (
                (item.CardDeductionAmount * employee.Scale) /
                100
              ).toFixed(2);
              if (employee.SavingCardRate != null) {
                employee.SavingCardRateCommission = (
                  (employee.SavingCardPerformance * employee.SavingCardRate) /
                  100
                ).toFixed(2);
              } else {
                employee.SavingCardRateCommission = 0;
              }
              if (employee.SavingCardFixed != null) {
                employee.SavingCardFixedCommission = (
                  (employee.SavingCardPerformance / item.TotalAmount) *
                  employee.SavingCardFixed *
                  item.Quantity
                ).toFixed(2);
              }
            }
          } else {
            employee.SavingCardPerformance = 0;
            employee.SavingCardRateCommission = 0;
            employee.SavingCardFixedCommission = 0;
          }
        }

        //赠送卡抵扣
        if (itemType != 20) {
          //储值没有卡抵扣
          if (item.CardDeductionLargessAmount > 0) {
            if (
              employee.SavingCardLargessRate == null &&
              employee.SavingCardLargessFixed == null
            ) {
              employee.SavingCardLargessPerformance = 0;
              employee.SavingCardLargessRateCommission = 0;
              employee.SavingCardLargessFixedCommission = 0;
            } else {
              employee.SavingCardLargessPerformance = (
                (item.CardDeductionLargessAmount * employee.Scale) /
                100
              ).toFixed(2);
              if (employee.SavingCardLargessRate != null) {
                employee.SavingCardLargessRateCommission = (
                  (employee.SavingCardLargessPerformance *
                    employee.SavingCardLargessRate) /
                  100
                ).toFixed(2);
              } else {
                employee.SavingCardLargessRateCommission = 0;
              }
              if (employee.SavingCardLargessFixed != null) {
                employee.SavingCardLargessFixedCommission = (
                  (employee.SavingCardLargessPerformance / item.TotalAmount) *
                  employee.SavingCardLargessFixed *
                  item.Quantity
                ).toFixed(2);
              } else {
                employee.SavingCardLargessFixedCommission = 0;
              }
            }
          } else {
            employee.SavingCardLargessPerformance = 0;
            employee.SavingCardLargessRateCommission = 0;
            employee.SavingCardLargessFixedCommission = 0;
          }
        }

        //特殊补贴
        if (employee.SpecialBenefit != null) {
          if (itemType != 20) {
            //储值没有卡抵扣
            employee.SpecialBenefitCommission = (
              ((parseFloat(item.PayAmount) +
                parseFloat(item.CardDeductionTotalAmount)) /
                item.AccountTotalAmount) *
              employee.SpecialBenefit *
              item.Quantity
            ).toFixed(2);
          } else {
            // employee.SpecialBenefitCommission = (item.PayAmount / item.TotalAmount * employee.SpecialBenefit * item.Quantity).toFixed(2);
            employee.SpecialBenefitCommission = (
              (item.TotalAmount / item.AccountTotalAmount) *
              employee.SpecialBenefit *
              item.Quantity
            ).toFixed(2);
          }
        } else {
          employee.SpecialBenefitCommission = 0;
        }
      } else {
        //10：销售
        //现金
        if (item.PayAmount > 0) {
          if (employee.PayRate == null && employee.PayFixed == null) {
            employee.PayPerformance = 0;
            employee.PayRateCommission = 0;
            employee.PayFixedCommission = 0;
          } else {
            employee.PayPerformance = (
              (item.PayAmount * employee.Scale) /
              100
            ).toFixed(2);
            if (employee.PayRate != null) {
              employee.PayRateCommission = (
                (employee.PayPerformance * employee.PayRate) /
                100
              ).toFixed(2);
            } else {
              employee.PayRateCommission = 0;
            }
            if (employee.PayFixed != null) {
              employee.PayFixedCommission = (
                (employee.PayPerformance / item.TotalAmount) *
                employee.PayFixed *
                item.Quantity
              ).toFixed(2);
            } else {
              employee.PayFixedCommission = 0;
            }
          }
        } else {
          employee.PayPerformance = 0;
          employee.PayRateCommission = 0;
          employee.PayFixedCommission = 0;
        }
        //卡抵扣
        if (itemType != 20) {
          //储值没有卡抵扣
          if (item.CardDeductionAmount > 0) {
            if (
              employee.SavingCardRate == null &&
              employee.SavingCardFixed == null
            ) {
              employee.SavingCardPerformance = 0;
              employee.SavingCardRateCommission = 0;
              employee.SavingCardFixedCommission = 0;
            } else {
              employee.SavingCardPerformance = (
                (item.CardDeductionAmount * employee.Scale) /
                100
              ).toFixed(2);
              if (employee.SavingCardRate != null) {
                employee.SavingCardRateCommission = (
                  (employee.SavingCardPerformance * employee.SavingCardRate) /
                  100
                ).toFixed(2);
              } else {
                employee.SavingCardRateCommission = 0;
              }
              if (employee.SavingCardFixed != null) {
                employee.SavingCardFixedCommission = (
                  (employee.SavingCardPerformance / item.TotalAmount) *
                  employee.SavingCardFixed *
                  item.Quantity
                ).toFixed(2);
              }
            }
          } else {
            employee.SavingCardPerformance = 0;
            employee.SavingCardRateCommission = 0;
            employee.SavingCardFixedCommission = 0;
          }
        }
        //赠送卡抵扣
        if (itemType != 20) {
          //储值没有卡抵扣
          if (item.CardDeductionLargessAmount > 0) {
            if (
              employee.SavingCardLargessRate == null &&
              employee.SavingCardLargessFixed == null
            ) {
              employee.SavingCardLargessPerformance = 0;
              employee.SavingCardLargessRateCommission = 0;
              employee.SavingCardLargessFixedCommission = 0;
            } else {
              employee.SavingCardLargessPerformance = (
                (item.CardDeductionLargessAmount * employee.Scale) /
                100
              ).toFixed(2);
              if (employee.SavingCardLargessRate != null) {
                employee.SavingCardLargessRateCommission = (
                  (employee.SavingCardLargessPerformance *
                    employee.SavingCardLargessRate) /
                  100
                ).toFixed(2);
              } else {
                employee.SavingCardLargessRateCommission = 0;
              }
              if (employee.SavingCardLargessFixed != null) {
                employee.SavingCardLargessFixedCommission = (
                  (employee.SavingCardLargessPerformance / item.TotalAmount) *
                  employee.SavingCardLargessFixed *
                  item.Quantity
                ).toFixed(2);
              } else {
                employee.SavingCardLargessFixedCommission = 0;
              }
            }
          } else {
            employee.SavingCardLargessPerformance = 0;
            employee.SavingCardLargessRateCommission = 0;
            employee.SavingCardLargessFixedCommission = 0;
          }
        }
        //特殊补贴
        if (employee.SpecialBenefit != null) {
          if (itemType != 20) {
            //储值没有卡抵扣
            employee.SpecialBenefitCommission = (
              ((parseFloat(item.PayAmount) +
                parseFloat(item.CardDeductionTotalAmount)) /
                item.TotalAmount) *
              employee.SpecialBenefit *
              item.Quantity
            ).toFixed(2);
          } else {
            employee.SpecialBenefitCommission = (
              (item.PayAmount / item.TotalAmount) *
              employee.SpecialBenefit *
              item.Quantity
            ).toFixed(2);
          }
        } else {
          employee.SpecialBenefitCommission = 0;
        }
      }
    },
    //计算套餐卡经手人业绩提成
    calculatePackageSaleHandlerRate(packageCard, item, employee, itemType) {
      //itemType（10：项目、20：储值卡、30：时效卡、40：通用次卡、50：产品）
      //现金
      if (!packageCard.IsLargess && !item.IsLargess) {
        if (item.PayAmount > 0) {
          if (employee.PayRate == null && employee.PayFixed == null) {
            employee.PayPerformance = 0;
            employee.PayRateCommission = 0;
            employee.PayFixedCommission = 0;
          } else {
            employee.PayPerformance = (
              (item.PayAmount * employee.Scale) /
              100
            ).toFixed(2);
            if (employee.PayRate != null) {
              employee.PayRateCommission = (
                (employee.PayPerformance * employee.PayRate) /
                100
              ).toFixed(2);
            } else {
              employee.PayRateCommission = 0;
            }
            if (employee.PayFixed != null) {
              employee.PayFixedCommission = (
                (employee.PayPerformance / item.TotalAmount) *
                employee.PayFixed *
                item.Quantity *
                packageCard.Quantity
              ).toFixed(2);
            } else {
              employee.PayFixedCommission = 0;
            }
          }
        } else {
          employee.PayPerformance = 0;
          employee.PayRateCommission = 0;
          employee.PayFixedCommission = 0;
        }
        //卡抵扣
        if (itemType != 20) {
          //储值没有卡抵扣
          if (item.CardDeductionAmount > 0) {
            if (
              employee.SavingCardRate == null &&
              employee.SavingCardFixed == null
            ) {
              employee.SavingCardPerformance = 0;
              employee.SavingCardRateCommission = 0;
              employee.SavingCardFixedCommission = 0;
            } else {
              employee.SavingCardPerformance = (
                (item.CardDeductionAmount * employee.Scale) /
                100
              ).toFixed(2);
              if (employee.SavingCardRate != null) {
                employee.SavingCardRateCommission = (
                  (employee.SavingCardPerformance * employee.SavingCardRate) /
                  100
                ).toFixed(2);
              } else {
                employee.SavingCardRateCommission = 0;
              }
              if (employee.SavingCardFixed != null) {
                employee.SavingCardFixedCommission = (
                  (employee.SavingCardPerformance / item.TotalAmount) *
                  employee.SavingCardFixed *
                  item.Quantity *
                  packageCard.Quantity
                ).toFixed(2);
              }
            }
          } else {
            employee.SavingCardPerformance = 0;
            employee.SavingCardRateCommission = 0;
            employee.SavingCardFixedCommission = 0;
          }
        }
        //赠送卡抵扣
        if (itemType != 20) {
          //储值没有卡抵扣
          if (item.CardDeductionLargessAmount > 0) {
            if (
              employee.SavingCardLargessRate == null &&
              employee.SavingCardLargessFixed == null
            ) {
              employee.SavingCardLargessPerformance = 0;
              employee.SavingCardLargessRateCommission = 0;
              employee.SavingCardLargessFixedCommission = 0;
            } else {
              employee.SavingCardLargessPerformance = (
                (item.CardDeductionLargessAmount * employee.Scale) /
                100
              ).toFixed(2);
              if (employee.SavingCardLargessRate != null) {
                employee.SavingCardLargessRateCommission = (
                  (employee.SavingCardLargessPerformance *
                    employee.SavingCardLargessRate) /
                  100
                ).toFixed(2);
              } else {
                employee.SavingCardLargessRateCommission = 0;
              }
              if (employee.SavingCardLargessFixed != null) {
                employee.SavingCardLargessFixedCommission = (
                  (employee.SavingCardLargessPerformance / item.TotalAmount) *
                  employee.SavingCardLargessFixed *
                  item.Quantity *
                  packageCard.Quantity
                ).toFixed(2);
              } else {
                employee.SavingCardLargessFixedCommission = 0;
              }
            }
          } else {
            employee.SavingCardLargessPerformance = 0;
            employee.SavingCardLargessRateCommission = 0;
            employee.SavingCardLargessFixedCommission = 0;
          }
        }
        //特殊补贴
        if (employee.SpecialBenefit != null) {
          if (itemType != 20) {
            //储值没有卡抵扣
            employee.SpecialBenefitCommission = (
              ((parseFloat(item.PayAmount) +
                parseFloat(item.CardDeductionTotalAmount)) /
                item.TotalAmount) *
              employee.SpecialBenefit *
              item.Quantity *
              packageCard.Quantity
            ).toFixed(2);
          } else {
            employee.SpecialBenefitCommission = (
              (item.PayAmount / item.TotalAmount) *
              employee.SpecialBenefit *
              item.Quantity *
              packageCard.Quantity
            ).toFixed(2);
          }
        } else {
          employee.SpecialBenefitCommission = 0;
        }
      }
    },
    //修改套餐卡经手人比例
    changePackageSaleHandlerRate(packageCard, packageHandler, employee) {
      packageCard.Project.forEach((item) => {
        //项目
        item.SaleBillHandler.forEach((handler) => {
          handler.Employee.forEach((emp) => {
            if (
              packageHandler.SaleHandlerID == handler.SaleHandlerID &&
              employee.EmployeeID == emp.EmployeeID
            ) {
              emp.Scale = employee.Scale;
              this.calculatePackageSaleHandlerRate(packageCard, item, emp, 10);
            }
          });
        });
      });
      packageCard.SavingCard.forEach((item) => {
        //储值卡
        item.SaleBillHandler.forEach((handler) => {
          handler.Employee.forEach((emp) => {
            if (
              packageHandler.SaleHandlerID == handler.SaleHandlerID &&
              employee.EmployeeID == emp.EmployeeID
            ) {
              emp.Scale = employee.Scale;
              this.calculatePackageSaleHandlerRate(packageCard, item, emp, 20);
            }
          });
        });
      });
      packageCard.TimeCard.forEach((item) => {
        //时效卡
        item.SaleBillHandler.forEach((handler) => {
          handler.Employee.forEach((emp) => {
            if (
              packageHandler.SaleHandlerID == handler.SaleHandlerID &&
              employee.EmployeeID == emp.EmployeeID
            ) {
              emp.Scale = employee.Scale;
              this.calculatePackageSaleHandlerRate(packageCard, item, emp, 30);
            }
          });
        });
      });
      packageCard.GeneralCard.forEach((item) => {
        //通用次卡
        item.SaleBillHandler.forEach((handler) => {
          handler.Employee.forEach((emp) => {
            if (
              packageHandler.SaleHandlerID == handler.SaleHandlerID &&
              employee.EmployeeID == emp.EmployeeID
            ) {
              emp.Scale = employee.Scale;
              this.calculatePackageSaleHandlerRate(packageCard, item, emp, 40);
            }
          });
        });
      });
      packageCard.Product.forEach((item) => {
        //产品
        item.SaleBillHandler.forEach((handler) => {
          handler.Employee.forEach((emp) => {
            if (
              packageHandler.SaleHandlerID == handler.SaleHandlerID &&
              employee.EmployeeID == emp.EmployeeID
            ) {
              emp.Scale = employee.Scale;
              this.calculatePackageSaleHandlerRate(packageCard, item, emp, 50);
            }
          });
        });
      });
    },
    //修改备注
    updateRemark() {
      var that = this;
      var params = {
        SaleBillID: that.SaleBillID,
        Remark: that.Remark,
      };
      API.updateRemark(params).then((res) => {
        if (res.StateCode == 200) {
          that.saleOrderDetail.Remark = that.Remark;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //保存修改业绩
    saveSaleBill() {
      var that = this;
      that.savePerformanceCommissionLoading = true;
      var params = {
        SaleBillID: that.SaleBillID,
        ProjectEmployee: [],
        ProductEmployee: [],
        SavingCardEmployee: [],
        GeneralCardEmployee: [],
        TimeCardEmployee: [],
        PackageCardEmployee: {
          ProductEmployee: [],
          ProjectEmployee: [],
          SavingCardEmployee: [],
          GeneralCardEmployee: [],
          TimeCardEmployee: [],
        },
      };
      //项目
      that.PerformanceInfo.Project.forEach((card) => {
        card.SaleBillHandler.forEach((hand) => {
          hand.Employee.forEach((emp) => {
            params.ProjectEmployee.push({
              SaleBillProjectID: card.SaleBillProjectID,
              ProjectID: card.ProjectID,
              SaleHandlerID: hand.SaleHandlerID,
              EmployeeID: emp.EmployeeID,
              Scale: emp.Scale,
              PayPerformance: emp.PayPerformance || 0,
              PayRateCommission: emp.PayRateCommission || 0,
              PayFixedCommission: emp.PayFixedCommission || 0,
              SavingCardPerformance: emp.SavingCardPerformance || 0,
              SavingCardRateCommission: emp.SavingCardRateCommission || 0,
              SavingCardFixedCommission: emp.SavingCardFixedCommission || 0,
              SavingCardLargessPerformance:
                emp.SavingCardLargessPerformance || 0,
              SavingCardLargessRateCommission:
                emp.SavingCardLargessRateCommission || 0,
              SavingCardLargessFixedCommission:
                emp.SavingCardLargessFixedCommission || 0,
              SpecialBenefitCommission: emp.SpecialBenefitCommission || 0,
            });
          });
        });
      });
      //产品
      that.PerformanceInfo.Product.forEach((card) => {
        card.SaleBillHandler.forEach((hand) => {
          hand.Employee.forEach((emp) => {
            params.ProductEmployee.push({
              SaleBillProductID: card.SaleBillProductID,
              ProductID: card.ProductID,
              SaleHandlerID: hand.SaleHandlerID,
              EmployeeID: emp.EmployeeID,
              Scale: emp.Scale,
              PayPerformance: emp.PayPerformance || 0,
              PayRateCommission: emp.PayRateCommission || 0,
              PayFixedCommission: emp.PayFixedCommission || 0,
              SavingCardPerformance: emp.SavingCardPerformance || 0,
              SavingCardRateCommission: emp.SavingCardRateCommission || 0,
              SavingCardFixedCommission: emp.SavingCardFixedCommission || 0,
              SavingCardLargessPerformance:
                emp.SavingCardLargessPerformance || 0,
              SavingCardLargessRateCommission:
                emp.SavingCardLargessRateCommission || 0,
              SavingCardLargessFixedCommission:
                emp.SavingCardLargessFixedCommission || 0,
              SpecialBenefitCommission: emp.SpecialBenefitCommission || 0,
            });
          });
        });
      });
      //储值卡
      that.PerformanceInfo.SavingCard.forEach((card) => {
        card.SaleBillHandler.forEach((hand) => {
          hand.Employee.forEach((emp) => {
            params.SavingCardEmployee.push({
              SaleBillSavingCardID: card.SaleBillSavingCardID,
              SavingCardID: card.SavingCardID,
              SaleHandlerID: hand.SaleHandlerID,
              EmployeeID: emp.EmployeeID,
              Scale: emp.Scale,
              PayPerformance: emp.PayPerformance || 0,
              PayRateCommission: emp.PayRateCommission || 0,
              PayFixedCommission: emp.PayFixedCommission || 0,
              SpecialBenefitCommission: emp.SpecialBenefitCommission || 0,
            });
          });
        });
      });
      //通用次卡
      that.PerformanceInfo.GeneralCard.forEach((card) => {
        card.SaleBillHandler.forEach((hand) => {
          hand.Employee.forEach((emp) => {
            params.GeneralCardEmployee.push({
              SaleBillGeneralCardID: card.SaleBillGeneralCardID,
              GeneralCardID: card.GeneralCardID,
              SaleHandlerID: hand.SaleHandlerID,
              EmployeeID: emp.EmployeeID,
              Scale: emp.Scale,
              PayPerformance: emp.PayPerformance || 0,
              PayRateCommission: emp.PayRateCommission || 0,
              PayFixedCommission: emp.PayFixedCommission || 0,
              SavingCardPerformance: emp.SavingCardPerformance || 0,
              SavingCardRateCommission: emp.SavingCardRateCommission || 0,
              SavingCardFixedCommission: emp.SavingCardFixedCommission || 0,
              SavingCardLargessPerformance:
                emp.SavingCardLargessPerformance || 0,
              SavingCardLargessRateCommission:
                emp.SavingCardLargessRateCommission || 0,
              SavingCardLargessFixedCommission:
                emp.SavingCardLargessFixedCommission || 0,
              SpecialBenefitCommission: emp.SpecialBenefitCommission || 0,
            });
          });
        });
      });
      //时效卡
      that.PerformanceInfo.TimeCard.forEach((card) => {
        card.SaleBillHandler.forEach((hand) => {
          hand.Employee.forEach((emp) => {
            params.TimeCardEmployee.push({
              SaleBillTimeCardID: card.SaleBillTimeCardID,
              TimeCardID: card.TimeCardID,
              SaleHandlerID: hand.SaleHandlerID,
              EmployeeID: emp.EmployeeID,
              Scale: emp.Scale,
              PayPerformance: emp.PayPerformance || 0,
              PayRateCommission: emp.PayRateCommission || 0,
              PayFixedCommission: emp.PayFixedCommission || 0,
              SavingCardPerformance: emp.SavingCardPerformance || 0,
              SavingCardRateCommission: emp.SavingCardRateCommission || 0,
              SavingCardFixedCommission: emp.SavingCardFixedCommission || 0,
              SavingCardLargessPerformance:
                emp.SavingCardLargessPerformance || 0,
              SavingCardLargessRateCommission:
                emp.SavingCardLargessRateCommission || 0,
              SavingCardLargessFixedCommission:
                emp.SavingCardLargessFixedCommission || 0,
              SpecialBenefitCommission: emp.SpecialBenefitCommission || 0,
            });
          });
        });
      });
      //套餐卡
      that.PerformanceInfo.PackageCard.forEach((PackageCard) => {
        PackageCard.Product.forEach((card) => {
          card.SaleBillHandler.forEach((hand) => {
            hand.Employee.forEach((emp) => {
              params.PackageCardEmployee.ProductEmployee.push({
                SaleBillPackageCardID: PackageCard.SaleBillPackageCardID,
                PackageCardID: PackageCard.PackageCardID,
                SaleBillPackageCardProductID: card.SaleBillPackageCardProductID,
                ProductID: card.ProductID,
                SaleHandlerID: hand.SaleHandlerID,
                EmployeeID: emp.EmployeeID,
                Scale: emp.Scale,
                PayPerformance: parseFloat(emp.PayPerformance) || 0,
                PayRateCommission: parseFloat(emp.PayRateCommission) || 0,
                PayFixedCommission: parseFloat(emp.PayFixedCommission) || 0,
                SavingCardPerformance:
                  parseFloat(emp.SavingCardPerformance) || 0,
                SavingCardRateCommission:
                  parseFloat(emp.SavingCardRateCommission) || 0,
                SavingCardFixedCommission:
                  parseFloat(emp.SavingCardFixedCommission) || 0,
                SavingCardLargessPerformance:
                  parseFloat(emp.SavingCardLargessPerformance) || 0,
                SavingCardLargessRateCommission:
                  parseFloat(emp.SavingCardLargessRateCommission) || 0,
                SavingCardLargessFixedCommission:
                  parseFloat(emp.SavingCardLargessFixedCommission) || 0,
                SpecialBenefitCommission:
                  parseFloat(emp.SpecialBenefitCommission) || 0,
              });
            });
          });
        });
        PackageCard.Project.forEach((card) => {
          card.SaleBillHandler.forEach((hand) => {
            hand.Employee.forEach((emp) => {
              params.PackageCardEmployee.ProjectEmployee.push({
                SaleBillPackageCardID: PackageCard.SaleBillPackageCardID,
                PackageCardID: PackageCard.PackageCardID,
                SaleBillPackageCardProjectID: card.SaleBillPackageCardProjectID,
                ProjectID: card.ProjectID,
                SaleHandlerID: hand.SaleHandlerID,
                EmployeeID: emp.EmployeeID,
                Scale: emp.Scale,
                PayPerformance: parseFloat(emp.PayPerformance) || 0,
                PayRateCommission: parseFloat(emp.PayRateCommission) || 0,
                PayFixedCommission: parseFloat(emp.PayFixedCommission) || 0,
                SavingCardPerformance:
                  parseFloat(emp.SavingCardPerformance) || 0,
                SavingCardRateCommission:
                  parseFloat(emp.SavingCardRateCommission) || 0,
                SavingCardFixedCommission:
                  parseFloat(emp.SavingCardFixedCommission) || 0,
                SavingCardLargessPerformance:
                  parseFloat(emp.SavingCardLargessPerformance) || 0,
                SavingCardLargessRateCommission:
                  parseFloat(emp.SavingCardLargessRateCommission) || 0,
                SavingCardLargessFixedCommission:
                  parseFloat(emp.SavingCardLargessFixedCommission) || 0,
                SpecialBenefitCommission:
                  parseFloat(emp.SpecialBenefitCommission) || 0,
              });
            });
          });
        });
        PackageCard.SavingCard.forEach((card) => {
          card.SaleBillHandler.forEach((hand) => {
            hand.Employee.forEach((emp) => {
              params.PackageCardEmployee.SavingCardEmployee.push({
                SaleBillPackageCardID: PackageCard.SaleBillPackageCardID,
                PackageCardID: PackageCard.PackageCardID,
                SaleBillPackageCardSavingCardID:
                  card.SaleBillPackageCardSavingCardID,
                SavingCardID: card.SavingCardID,
                SaleHandlerID: hand.SaleHandlerID,
                EmployeeID: emp.EmployeeID,
                Scale: emp.Scale,
                PayPerformance: parseFloat(emp.PayPerformance) || 0,
                PayRateCommission: parseFloat(emp.PayRateCommission) || 0,
                PayFixedCommission: parseFloat(emp.PayFixedCommission) || 0,
                SpecialBenefitCommission:
                  parseFloat(emp.SpecialBenefitCommission) || 0,
              });
            });
          });
        });
        PackageCard.GeneralCard.forEach((card) => {
          card.SaleBillHandler.forEach((hand) => {
            hand.Employee.forEach((emp) => {
              params.PackageCardEmployee.GeneralCardEmployee.push({
                SaleBillPackageCardID: PackageCard.SaleBillPackageCardID,
                PackageCardID: PackageCard.PackageCardID,
                SaleBillPackageCardGeneralCardID:
                  card.SaleBillPackageCardGeneralCardID,
                GeneralCardID: card.GeneralCardID,
                SaleHandlerID: hand.SaleHandlerID,
                EmployeeID: emp.EmployeeID,
                Scale: emp.Scale,
                PayPerformance: parseFloat(emp.PayPerformance) || 0,
                PayRateCommission: parseFloat(emp.PayRateCommission) || 0,
                PayFixedCommission: parseFloat(emp.PayFixedCommission) || 0,
                SavingCardPerformance:
                  parseFloat(emp.SavingCardPerformance) || 0,
                SavingCardRateCommission:
                  parseFloat(emp.SavingCardRateCommission) || 0,
                SavingCardFixedCommission:
                  parseFloat(emp.SavingCardFixedCommission) || 0,
                SavingCardLargessPerformance:
                  parseFloat(emp.SavingCardLargessPerformance) || 0,
                SavingCardLargessRateCommission:
                  parseFloat(emp.SavingCardLargessRateCommission) || 0,
                SavingCardLargessFixedCommission:
                  parseFloat(emp.SavingCardLargessFixedCommission) || 0,
                SpecialBenefitCommission:
                  parseFloat(emp.SpecialBenefitCommission) || 0,
              });
            });
          });
        });
        PackageCard.TimeCard.forEach((card) => {
          card.SaleBillHandler.forEach((hand) => {
            hand.Employee.forEach((emp) => {
              params.PackageCardEmployee.TimeCardEmployee.push({
                SaleBillPackageCardID: PackageCard.SaleBillPackageCardID,
                PackageCardID: PackageCard.PackageCardID,
                SaleBillPackageCardTimeCardID:
                  card.SaleBillPackageCardTimeCardID,
                TimeCardID: card.TimeCardID,
                SaleHandlerID: hand.SaleHandlerID,
                EmployeeID: emp.EmployeeID,
                Scale: emp.Scale,
                PayPerformance: parseFloat(emp.PayPerformance) || 0,
                PayRateCommission: parseFloat(emp.PayRateCommission) || 0,
                PayFixedCommission: parseFloat(emp.PayFixedCommission) || 0,
                SavingCardPerformance:
                  parseFloat(emp.SavingCardPerformance) || 0,
                SavingCardRateCommission:
                  parseFloat(emp.SavingCardRateCommission) || 0,
                SavingCardFixedCommission:
                  parseFloat(emp.SavingCardFixedCommission) || 0,
                SavingCardLargessPerformance:
                  parseFloat(emp.SavingCardLargessPerformance) || 0,
                SavingCardLargessRateCommission:
                  parseFloat(emp.SavingCardLargessRateCommission) || 0,
                SavingCardLargessFixedCommission:
                  parseFloat(emp.SavingCardLargessFixedCommission) || 0,
                SpecialBenefitCommission:
                  parseFloat(emp.SpecialBenefitCommission) || 0,
              });
            });
          });
        });
      });
      API.updateEmployeePerformanceCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "修改成功",
              duration: 2000,
            });

            if (that.saleOrderDetail.BillType == 10) {
              // 销售订单详情
              that.changePerformance = false;
              that.dialogVisible = false;
            } else if (that.saleOrderDetail.BillType == 20) {
              // 销售退单详情
              that.refundChangePerformance = false;
              that.refundDialogVisible = false;
            } else if (that.saleOrderDetail.BillType == 30) {
              // 销售补欠款订单详情
              that.arrearChangePerformance = false;
              that.arreardialogVisible = false;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.savePerformanceCommissionLoading = false;
        });
    },

    // 顾客
    saleCustomerData: function (queryString, cb) {
      var that = this;
      that.loading = true;
      var params = {
        Name: queryString ? queryString : "",
      };
      saleAPI
        .getSaleCustomer(params)
        .then((res) => {
          if (res.StateCode == 200) {
            cb(res.Data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    handleCustomerSelect(item) {
      if (item.ID != undefined) {
        this.customerID = item.ID;
        this.customerFullName = item.Name;
        this.customerPhoneNumber = item.PhoneNumber;
        this.customerName = item.Name + "【" + item.PhoneNumber + "】";
        // this.customerChange();
      } else {
        // this.addNewCustomer();
      }
    },

    removeCustomer() {
      var that = this;
      that.customerID = null;
      that.customerFullName = "";
      that.customerPhoneNumber = "";
      that.customerName = "";
    },

    /**  更新 顾客网络请求  */
    updateSaleBillBindCutsomer() {
      var that = this;
      that.changeMemberDialogVisible = false;
      var params = {
        BillID: that.saleOrderDetail.ID,
        CustomerID: that.customerID,
      };
      API.updateSaleBillBindCutsomer(params).then((res) => {
        if (res.StateCode == 200) {
          that.saleOrderDetail.Name = that.customerName;
          that.saleOrderDetail.CustomerID = " ";
          that.handleSearch();
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    /** 散客修改顾客后刷新 列表   */
    RefreshList() {
      this.handleSearch();
    },

    /**  获取小票配置信息  */
    getReceiptConfig() {
      var that = this;
      cashierAPI
        .getReceiptConfigBill()
        .then((res) => {
          if (res.StateCode == 200) {
            that.cashierReceipt = res.Data;
          } else {
            // that.$message.error({
            //   message: res.Message,
            //   duration: 2000,
            // })
          }
        })
        .finally(() => {});
    },

    /**  打印命令发起  */
    submitPrint() {
      let that = this;
      that.getprintSaleBillContent();
    },

    mathAbsData: function (item) {
      return Math.abs(item);
    },

    /**  获取打印内容  */
    getprintSaleBillContent() {
      let that = this;
      let params = {
        SaleBillID: that.saleOrderDetail.ID,
      };
      cashierAPI
        .printSaleBillContent(params)
        .then((res) => {
          if (res.StateCode == 200) {
            for (
              let index = 0;
              index < that.cashierReceipt.PrintQuantity;
              index++
            ) {
              printReceipt.doActionPrint(res.Data.printDocuments, (request) => {
                socket.send(JSON.stringify(request));
              });
            }
          }
        })
        .finally(() => {});
    },

    // 姓名隐藏
    formatName(name) {
      return printReceipt.hiddenName(name);
    },
    // 手机号隐藏
    formatPhone(phone) {
      return printReceipt.hiddenPhone(phone);
    },

    /**  销售退款订单打印内容  */
    printRefundSaleBillContent() {
      var that = this;
      let params = {
        SaleBillID: that.saleOrderDetail.ID,
      };
      cashierAPI
        .printRefundSaleBillContent(params)
        .then((res) => {
          if (res.StateCode == 200) {
            for (
              let index = 0;
              index < that.cashierReceipt.PrintQuantity;
              index++
            ) {
              printReceipt.doActionPrint(res.Data.printDocuments, (request) => {
                socket.send(JSON.stringify(request));
              });
            }
          }
        })
        .finally(() => {});
    },

    /**  补欠款订单打印内容  */
    printArrearSaleBillContent() {
      var that = this;
      let params = {
        SaleBillID: that.saleOrderDetail.ID,
      };
      cashierAPI
        .printArrearSaleBillContent(params)
        .then((res) => {
          if (res.StateCode == 200) {
            for (let index = 0; index < res.Data.copies; index++) {
              printReceipt.doActionPrint(res.Data.printDocuments, (request) => {
                socket.send(JSON.stringify(request));
              });
            }
          }
        })
        .finally(() => {});
    },

    saveRemarkClick(item,type,callback){
      let that = this
      console.log(item)
      console.log(type)
      console.log(callback)
      that.updateProductRemark_saleBill(item,type,callback)
    },


    /**  修改备注信息  */
    async updateProductRemark_saleBill(item,type,callback){
      let that = this
      let params = {
        SaleBillGoodID:item.SaleBillGoodID,
        Remark:item.Remark
      };
      console.log(type)
      let res = await API[type](params);
      if(res.StateCode == 200){
        that.$message.success("更新成功");
        callback()
      }
      else{
        that.$message.error(res.Message);
      }
     },
    
  },
  mounted() {
    this.$base64Img().then((res) => {});
    var that = this;
    that.isCancel = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-SaleBill-Cancel"
    );
    that.isModifyPerformanceCommission = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-SaleBill-ModifyPerformanceCommission"
    );
    that.isDelete = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Order-SaleBill-DeleteAttachment"
    );
    socket = printReceipt.getSocket((res) => {
      if (res.status == "success") {
        that.$message.success({
          message: "打印成功",
          duration: 2000,
        });

        that.cashierReceiptDialogVisible = false;
        that.refundCashierReceiptDialogVisible = false;
      }
    });

    // var date = new Date(),
    //   y = date.getFullYear(),
    //   m = date.getMonth();
    // that.searchData.createTime = [new Date(y, m, 1), new Date()];
    that.userInfo = JSON.parse(localStorage.getItem("access-user"));
    that.handleSearch();
    that.getProjectSaleHandlerList();
    that.getProductSaleHandlerList();
    that.getSaveCardSaleHandlerList();
    that.getTimeSaleHandlerList();
    that.getGenerSaleHandlerList();
    that.getPackageSaleHandlerList();
  },
};
</script>
<style lang="scss">
.SaleBill {
  .el-scrollbar_height {
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .saleInfo {
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
    
  }
  
  .saleHandler {
    .el-form-item__label {
      font-size: 12px !important;
      line-height: 18px;
      color: #c0c4cc;
    }
    .el-form-item__content {
      font-size: 12px !important;
      line-height: 20px;
      color: #c0c4cc;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }
  .sale-ModifyPerformanceCommission-Handler {
    .el-form-item__label {
      font-size: 12px !important;
      line-height: 26px;
    }
    .el-form-item__content {
      font-size: 12px !important;
      line-height: 26px;
    }
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .row_header {
    background-color: #ecf8ff;
    padding: 10px;
    color: #303133;
  }
  .row_header_package_detail {
    background-color: #f5f7fa;
    padding: 8px;
    color: #303133;
  }
  .row_handler_header {
    background-color: #f5f7fa;
    font-size: 12px;
    padding: 10px;
    color: #303133;
  }
  .payInfo {
    .el-card__header {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .el-card__body {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .input_type {
    .el-input-group__append {
      padding: 0 10px;
    }
  }
  .el-input__inner {
    padding-right: 0;
  }
}
</style>